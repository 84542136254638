import { createContext, useEffect, useState, useContext } from 'react'
import { toPlural, getOrgName, getOrgNr } from 'utils/data'
import { addTeamMeta } from 'data/meta'
import {
  getFirebase,
  cleanData,
  FieldValue,
  convertDates,
} from 'utils/firebase'
import { UserContext } from './User'

export const TeamContext = createContext({
  loading: true,
})

export const TeamProvider = ({ children }) => {
  const { teams, activeTeam } = useContext(UserContext)
  const team = teams
    ? teams[activeTeam]
      ? activeTeam
      : Object.keys(teams).sort()[0]
    : undefined

  const [state, setState] = useState({
    loading: true,
  })

  const { db } = getFirebase()

  useEffect(() => {
    const unsubscribe = team
      ? db
          .collection('teams')
          .doc(team)
          .onSnapshot(doc => {
            const teamData = addTeamMeta(
              { id: doc.id, ...convertDates(doc.data()) },
              ['properties', 'plans', 'reports']
            )
            setState({
              loading: false,
              id: team,
              name: getOrgName(teamData),
              orgNr: getOrgNr(teamData),
              ...teamData,
            })
          })
      : null

    return () => {
      unsubscribe?.()
      setState({ loading: true })
    }
  }, [db, team])

  const set = data =>
    db.collection('teams').doc(team).set(data, { merge: true })

  const saveProperty = (
    type,
    objectId,
    propertyId,
    { value, document, comment }
  ) => {
    return set({
      [toPlural(type)]: {
        [objectId]: {
          [propertyId]: cleanData(
            {
              value,
              document: document || undefined,
              comment: comment || undefined,
            },
            true
          ),
        },
      },
    })
  }

  const saveListItem = (
    type,
    objectId,
    propertyId,
    itemId,
    { value, document, comment }
  ) =>
    set({
      [toPlural(type)]: {
        [objectId]: {
          [propertyId]: {
            items: {
              [itemId]: value
                ? cleanData(
                    {
                      value,
                      document: document || undefined,
                      comment: comment || undefined,
                    },
                    true
                  )
                : FieldValue.delete(),
            },
          },
        },
      },
    })

  return (
    <TeamContext.Provider
      value={{
        ...state,
        set,
        saveProperty,
        saveListItem,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}
