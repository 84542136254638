import { useTeam } from 'providers/Session'
import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import { getFirebase } from 'utils/firebase'
import { CodeError } from 'utils/error'
import FormPopup, { Errors, ButtonRow } from 'components/Popup/FormPopup'

const NewReporterButton = () => (
  <FormPopup
    icon="plus"
    label="Ny Kund"
    dialogWidth="20rem"
    buttonStyle="normal"
  >
    <NewReporterForm />
  </FormPopup>
)

const NewReporterForm = ({ close }) => {
  const { set, reporters } = useTeam()

  const addReporter = async ({ orgNr, newUser, email }) => {
    if (newUser) {
      throw new CodeError('uniplemented', 'Not implemented yet')
    }

    const { db } = getFirebase()
    const team = await db
      .doc(`orgmap/${orgNr}`)
      .get()
      .then(doc => doc.data())
    if (!team) {
      throw new CodeError('not-found', 'Org is not in sciigo')
    }
    if (team.type === 'creditor') {
      throw new CodeError('invalid-argument', 'Org is a creditor in Sciigo')
    }

    if (reporters?.[team.teamId]) {
      throw new CodeError('already-exists', 'Reporter is already connected')
    }

    await set({
      reporters: {
        [team.teamId]: {
          name: {
            value: team.name,
          },
          orgNr: { value: orgNr },
          pending: true,
        },
      },
    })
  }

  return (
    <Formik
      initialValues={{
        orgNr: '',
        newUser: false,
        email: '',
      }}
      onSubmit={async (
        { orgNr, newUser, email },
        { setSubmitting, setErrors, setValues, setStatus }
      ) => {
        try {
          await addReporter({
            orgNr: orgNr.trim(),
            newUser,
            email: email.trim(),
          })
          setStatus({ done: true })
          setTimeout(close, 1000)
        } catch (e) {
          console.error(e)
          if (e.code === 'not-found') {
            console.log('Not found')
            setValues({ newUser: true, orgNr })
          } else {
            setErrors({ err: 'Något gick fel' })
          }
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, values, errors, status, setValues }) => (
        <Form>
          <h2>Ny kund</h2>
          <FormField name="orgNr" label="Organisationsnummer" />
          <FormField name="newUser" type="hidden" />
          {values.newUser && (
            <>
              <p>
                '{values.orgNr}' är inte ansluten till Sciigo. Vill du bjuda in
                dem?
              </p>
              <FormField name="email" type="email" label="Epost" />
            </>
          )}
          <Errors errors={errors} />
          <ButtonRow
            disabled={isSubmitting || !values.orgNr}
            loading={isSubmitting}
            title={values.newUser ? 'Bjud in' : 'Skicka förfrågan'}
            done={status?.done}
            successMessage="Förfrågan skickades"
          />
        </Form>
      )}
    </Formik>
  )
}

export default NewReporterButton
