import { mapValues } from 'lodash'
import { toOptions } from 'utils/data'
import addPropMeta from './addPropMeta'

const meta = {
  pending: 'primitive',
  name: {
    label: 'Namn',
    editable: false,
    sourced: false,
  },
  orgNr: {
    label: 'Organisationsnummer',
    editable: false,
    sourced: false,
  },
  contact: {
    label: 'Ansvarig rådgivare',
    type: 'select',
    options: ({ team }) => toOptions({ '': '—', ...team.users }),
    sourced: false,
  },
}

const transform = (reporterData, env) =>
  mapValues(meta, (attrMeta, key) =>
    addPropMeta(reporterData[key], attrMeta, env)
  )

export default transform
