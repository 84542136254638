import { H1, H2, P, Table, Tr, Th, Td } from 'components/Pdf/elements'
import { InlineMarkdown as Markdown } from 'components/Markdown/Pdf'
import { Row, Column } from 'components/Pdf/elements/Layout'
// import Icon from 'components/Pdf/Icon'
import Coverage from './Coverage'

const Property = ({
  title,
  type,
  details,
  comment,
  energyClass,
  certifications,
  focus,
  progress,
}) => {
  return (
    <>
      <Row style={{ alignItems: 'center' }}>
        <Column width={7 / 10}>
          <H1>{title}</H1>
        </Column>
        <Column width={3 / 10}>
          <Coverage data={[progress, 0, 1 - progress]} />
        </Column>
        <Column>
          <H2 style={{ margin: '1em 0 0.3em' }}>Detaljer</H2>
          <Table style={{ margin: 0 }} columns={[7, 3]}>
            <Tr />
            {details.map(({ label, value, indent = 0 }) => (
              <Tr key={label}>
                <Th style={{ paddingLeft: 1.2 + indent + 'em' }}>
                  <Markdown>{label}</Markdown>
                </Th>
                <Td>
                  <Markdown>{value.toString()}</Markdown>
                </Td>
              </Tr>
            ))}
          </Table>
        </Column>
      </Row>
      <H2 style={{ margin: '3em 0 0.3em' }}>Rådgivarens kommentarer</H2>
      <P>{comment ?? '(Inga kommentarer)'}</P>
    </>
  )
}

export default Property
