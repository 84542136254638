import { mapValues } from 'lodash'
import { toSingular } from 'utils/data'
import property from './property'
import plan from './plan'
import organization from './organization'
import request from './request'
import report from './report'
import message from './message'
import reporter from './reporter'
import creditor from './creditor'

const meta = {
  property,
  plan,
  organization,
  request,
  report,
  message,
  reporter,
  creditor,
}

export const addMeta = (data, type, env) =>
  meta[type] ? meta[type](data, env) : data

export const addTeamMeta = (data, exclude = [], env) =>
  mapValues(data, (value, type) =>
    !exclude.includes(type)
      ? meta[type]
        ? addMeta(value, type, {
            team: data,
            object: value,
            id: value.id,
            ...(env ?? {}),
          })
        : meta[toSingular(type)]
        ? mapValues(value, (obj, id) =>
            addMeta(obj, toSingular(type), {
              team: data,
              object: obj,
              id,
              ...(env ?? {}),
            })
          )
        : value
      : value
  )
