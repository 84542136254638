import { View, Link, Text, Image } from './elements'

export { default as Page } from './Page'
export { default as Document } from './Document'
export * from './List'
export * from './Table'
export * from './TextTags'
export const Div = View
export const A = Link
export const Span = Text
export const Img = Image
