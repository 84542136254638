import styled from 'styled-components'
import { useState } from 'react'

import { useMailbox } from 'providers/Messaging'
import { useTeam, useReport } from 'providers/Session'

import Page from 'components/Page'
import Loader from 'components/Loader'
import Switch from 'components/Switch'
import Card from 'components/Card'
import Button from 'components/Button'
import UnstyledList from 'components/List'
import { sortByDate, getLatestSnapshotId, isReportOpen } from 'utils/data'

import Message from './Message'
import NewMessageForm from './NewMessageForm'

const List = styled(UnstyledList)`
  li {
    justify-content: stretch;
  }
`

const ReplyButtonWrapper = styled.div`
  position: relative;
  z-index: 100;
  margin-bottom: 0.5rem;
  > button {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    padding: 0.9rem;
  }
`

const ReportDiscussion = ({ match }) => {
  const { id: reportId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: report, loading: loadingReport } = useReport(reportId, teamData)
  const { loading: loadingMailbox, inbox, outbox } = useMailbox()
  const [isNewMessageFormOpen, setNewMessageFormOpen] = useState()

  const loading = loadingTeam || loadingReport || loadingMailbox
  const snapshotId = getLatestSnapshotId(report)
  const messages = loading
    ? []
    : [...Object.values(inbox), ...Object.values(outbox)]
        .filter(({ data }) => data?.reportId === reportId)
        .sort(sortByDate('timestamp', true))
        .map(message => ({
          ...message,
          outdated:
            message.data.snapshot && message.data.snapshot !== snapshotId,
          self: message.to !== undefined,
        }))

  const isCreditor = teamData?.teamData?.type === 'creditor'

  return (
    <Page title="Diskussion">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Switch
            labels={[
              'Rapport',
              ...(isCreditor ? ['Kommentarer'] : []),
              'Diskussion',
            ]}
            urls={[
              `/report/${reportId}`,
              ...(isCreditor ? [`/report/${reportId}/review`] : []),
              `/report/${reportId}/discussion`,
            ]}
            activeIndex={isCreditor ? 2 : 1}
          />
          <Card title="Diskussion">
            {isNewMessageFormOpen ? (
              <NewMessageForm
                reportId={reportId}
                report={report}
                teamData={teamData}
                close={() => setNewMessageFormOpen(false)}
              />
            ) : (
              <ReplyButtonWrapper>
                <Button
                  disabled={!isReportOpen(report)}
                  onClick={() => {
                    console.log('Clicked')
                    setNewMessageFormOpen(true)
                  }}
                  icon="reply"
                />
              </ReplyButtonWrapper>
            )}

            <List data={messages}>
              <Message />
            </List>
          </Card>
        </>
      )}
    </Page>
  )
}

export default ReportDiscussion
