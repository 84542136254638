import styled from 'styled-components'
import Value, { ValueWrapper } from 'components/Value'
import { InlineMarkdown } from 'components/Markdown'
import CheckMark from './CheckMark'

// TODO: Some of these should be more generic components
// Others should be given better names
// But the report views will probably be redone pretty significantly
// once we have more complete requirements, so let's not get hasty

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`

export const Column = styled.div`
  padding: 1.2rem;
  width: 50%;
  h1,
  h2 {
    font-size: 1.2rem;
    letter-spacing: 0;
    font-weight: 500;
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
    margin-bottom: 0.5rem;
    &,
    * {
      text-transform: uppercase;
    }
  }
  h1 {
    font-size: 1.6rem;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 0.5rem;
  > * + * {
    margin-left: 0.5rem;
  }
`
export const ReportWrapper = styled.div``

export const Label = styled(InlineMarkdown)`
  p {
    font-weight: 500;
  }
`

const StyledValue = styled(Value)`
  ${ValueWrapper}+${ValueWrapper} {
    margin-top: 0.1rem;
  }
`

export const ReportValue = ({ data }) => (
  <StyledValue
    value={data.value}
    displayValue={data.displayValue}
    type={data.type}
    unit={data.unit}
  />
)

export const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
  li {
    display: flex;
    justify-content: stretch;
    margin-bottom: 0.3rem;
    > div:first-child {
      flex-grow: 1;
    }
    > div:last-child {
      flex-grow: 0;
    }
  }
`

export const FulfilledList = ({ qualifications = {} }) => (
  <StyledUl>
    {Object.entries(qualifications).map(([id, { title, fulfilled }]) => (
      <li key={id}>
        <div>{title}</div>
        <CheckMark value={fulfilled ? 'fulfilled' : 'lacking'} />
      </li>
    ))}
  </StyledUl>
)
