import { pick, isFunction } from 'lodash'
import Popup from 'components/Popup'
import Source from './Source'
import PropertyForm from './PropertyForm'
import Value from 'components/Value'
import Markdown from 'components/Markdown'
import BaseSection, { Label, RowWrapper } from './BaseSection'

const SubSection = ({
  indent,
  save,
  attributes,
  documents,
  filePath,
  ...attribute
}) => (
  <>
    <Row
      header={true}
      {...attribute}
      documents={documents}
      save={save}
      filePath={filePath}
    />
    {attributes.map((attr, idx) => (
      <Row
        key={idx}
        save={save}
        {...attr}
        editable={false}
        sourced={false}
        indent={1}
        filePath={filePath}
      />
    ))}
  </>
)

const getTooltipText = ({ label, description, missing, inadequate }) =>
  `${
    description
      ? `

**Förklaring** 

${description}`
      : ''
  }${
    missing
      ? '\n\n' +
        Object.values(missing)
          .map(
            ({ label, description }) => `**Krav: ${label}**\n\n ${description}`
          )
          .join('\n')
      : ''
  }`

const Row = ({
  header,
  indent,
  documents,
  validate,
  save,
  filePath,
  missing,
  inadequate,
  ...attribute
}) => (
  <RowWrapper missing={!!missing}>
    <Label
      indent={indent}
      header={header}
      label={isFunction(attribute.label) ? 'Function' : attribute.label}
      tooltip={getTooltipText({
        missing,
        inadequate,
        ...pick(attribute, 'label', 'description'),
      })}
    />
    {!header && (
      <Value
        {...pick(attribute, [
          'value',
          'type',
          'unit',
          'link',
          'displayValue',
          'description',
          'comment',
        ])}
      />
    )}
    {attribute.sourced !== false && attribute.value !== undefined && (
      <Source
        source={attribute.source}
        document={attribute.document}
        filePath={filePath}
      />
    )}
    {attribute.editable && save && (
      <Popup icon="pencil">
        <PropertyForm
          {...attribute}
          documents={documents}
          save={save}
          validate={validate}
        />
      </Popup>
    )}
  </RowWrapper>
)

const getSubsectionAttributes = ({ section, fieldArray }) =>
  fieldArray
    ? fieldArray.map(value => ({
        ...value.value,
        label: value.key.displayValue,
      }))
    : Object.entries(section).map(([id, attr]) => ({ id, ...attr }))

const StaticSection = ({
  title,
  attributes,
  documents,
  footer,
  save,
  filePath,
  editable = true,
}) => (
  <BaseSection title={title}>
    {attributes.map((attr, idx) =>
      attr.displaySection ? (
        <SubSection
          key={idx}
          save={save}
          {...attr}
          editable={attr.editable && editable}
          attributes={getSubsectionAttributes(attr)}
          documents={documents}
          filePath={filePath}
        />
      ) : (
        <Row
          key={idx}
          save={save}
          {...attr}
          editable={attr.editable && editable}
          documents={documents}
          filePath={filePath}
        />
      )
    )}
    {footer && (
      <RowWrapper>
        <Markdown>{footer}</Markdown>
      </RowWrapper>
    )}
  </BaseSection>
)

export const StaticSections = ({
  viewModel,
  data,
  editable = true,
  save,
  documents,
  filePath,
  qualificationResults,
}) =>
  Object.entries(viewModel).map(([key, meta], idx) => (
    <StaticSection
      key={idx}
      title={meta.label}
      attributes={meta.keys.map(key => ({
        id: key,
        ...data[key],
        editable: data[key].editable && editable,
        missing: qualificationResults?.missing[key],
        inadequate: qualificationResults?.inadequate[key],
      }))}
      documents={data.documents?.items}
      save={save}
      filePath={filePath}
    />
  ))

export default StaticSection
