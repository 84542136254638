import addPropMeta from './addPropMeta'
import { mapValues } from 'lodash'

const meta = {
  pending: 'primitive',
  requirements: 'primitive',
  name: {
    label: 'Namn',
    editable: false,
    sourced: false,
  },
  orgNr: {
    label: 'Organisationsnummer',
    editable: false,
    sourced: false,
  },
}

const transform = (reporterData, env) =>
  mapValues(meta, (attrMeta, key) =>
    addPropMeta(reporterData[key], attrMeta, env)
  )

export default transform
