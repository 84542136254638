import styled from 'styled-components'
import config from 'config'
import EnergyClass from 'components/EnergyClass'
import Value from 'components/Value'
import { BaseSection } from 'components/Section'

import TableReport from './TableReport'
import {
  ReportWrapper,
  Wrapper,
  Row,
  Column,
  Label,
  StyledUl,
  FulfilledList,
} from './common'

const StyledEnergyClass = styled(EnergyClass)`
  margin-right: 0.6rem;
`

const SummaryReport = ({ property, report, qualifications }) => {
  const {
    propertyId,
    address,
    energyClass,
    primaryEnergyDemand,
    certifications,
  } = property

  return (
    <BaseSection title="Överblick">
      <Wrapper>
        <Column>
          <h1>
            <Value {...propertyId} />
          </h1>
          <Row>
            <Value {...address} />
          </Row>
          {report?.reporter && (
            <>
              <h2>Avsändare</h2>
              <Row>{report.reporter.displayValue}</Row>
              <Row>Skickad {report.timestamp.displayValue}</Row>
            </>
          )}
          {report?.creditor && (
            <>
              <h2>Mottagare</h2>
              <Row>{report.creditor.displayValue}</Row>
            </>
          )}
        </Column>
        <Column>
          <Row>
            <StyledEnergyClass value={energyClass.value} />
            <Label>{`${primaryEnergyDemand.label}:`}</Label>
            <Value {...primaryEnergyDemand} />
          </Row>
          <h2>Certifieringar</h2>
          {certifications?.items ? (
            <StyledUl>
              {Object.values(certifications?.items ?? {}).map((item, idx) => (
                <li key={idx}>
                  <Value {...item} />
                </li>
              ))}
            </StyledUl>
          ) : (
            <p>Inga certifieringar</p>
          )}
          <h2>Specifika krav</h2>
          {qualifications && <FulfilledList qualifications={qualifications} />}
        </Column>
      </Wrapper>
    </BaseSection>
  )
}

const PropertyReport = ({
  qualifications,
  report,
  property,
  plans,
  organizations,
}) => (
  <ReportWrapper>
    <SummaryReport
      report={report}
      property={property}
      qualifications={qualifications}
    />
    {Object.entries(qualifications ?? {}).map(([type, qualification]) => (
      <TableReport
        key={type}
        property={property}
        plans={plans}
        organizations={organizations}
        {...qualification}
      />
    ))}
    {config.debug && <pre>{JSON.stringify(qualifications, null, 2)}</pre>}
  </ReportWrapper>
)

export default PropertyReport
