import { createElement } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { theme } from 'styled-tools'
import { InlineMarkdown } from 'components/Markdown'
import Unit from 'components/Unit'
import { isNumber } from 'utils/data'
import Value from 'components/Value'

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border-bottom: 2px solid ${theme('colors.borderPrimary')};

  th,
  td {
    padding: 0.6rem 1.2rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &:not(:first-child) {
      text-align: center;
    }
    &[type='text'],
    &[type='markdown'] {
      text-align: left;
    }

    // &[type="number"] {
    //   text-align: right;
    // }
  }

  thead th {
    font-weight: 500;
    ${InlineMarkdown} * {
      font-weight: 500;
    }
    border-bottom: 2px solid ${theme('colors.borderPrimary')};
  }

  tbody td,
  tbody th {
    border-bottom: 1px solid ${theme('colors.border')};
    font-weight: 300;
  }
`

export const compareNumberOrString = (a, b) =>
  isNumber(a) && isNumber(b)
    ? a - b
    : a?.localeCompare
    ? a.localeCompare(b, 'sv')
    : a && !b
    ? -1
    : !a && b
    ? 1
    : 0

// sort is [{column: string, order: 'asc|desc'}]
export const sortByColumn = sort => (r1, r2) => {
  const rule =
    sort.find(
      ({ column }) => compareNumberOrString(r1[column], r2[column]) !== 0
    ) ?? 0
  return (
    Math.sign(compareNumberOrString(r1[rule.column], r2[rule.column])) *
    (rule.order === 'asc' ? 1 : -1)
  )
}

const sortFn = (sort, columns) => (r1, r2) => {
  for (let { column, order } of sort) {
    let columnIdx = columns.indexOf(column)
    if (columnIdx !== -1) {
      let cmpValue = compareNumberOrString(
        r1.cellValues[columnIdx].displayValue,
        r2.cellValues[columnIdx].displayValue
      )
      if (cmpValue !== 0) {
        return Math.sign(cmpValue) * (order === 'asc' ? 1 : -1)
      }
    }
  }
  return compareNumberOrString(r1.label, r2.label)
}

const filterFn = (filter, columns) => row =>
  Object.entries(filter)
    .map(([columnPath, value]) => {
      if (columnPath === 'id') {
        return row.id === value
      }
      const columnIdx = columns.indexOf(columnPath)
      return columnIdx === -1 || row.cellValues[columnIdx].value === value
    })
    .reduce((acc, cur) => acc && cur, true)

////////////////////////////////////////////////////////////////////////////////

const Cell = ({ cellData: cell }) => (
  <td type={cell?.type?.split('.')[0]}>
    {cell?.component ? (
      createElement(cell.component, cell)
    ) : cell ? (
      <Value
        value={cell.value}
        displayValue={cell.displayValue}
        type={cell.type}
        unit={cell.unit}
      />
    ) : (
      ''
    )}
  </td>
)

////////////////////////////////////////////////////////////////////////////////

const SimpleTable = ({ columns, rows, footer, filter, sort, className }) => {
  const columnIds = columns.slice(1).map(({ id }) => id)

  const filteredRows = filter ? rows.filter(filterFn(filter, columnIds)) : rows
  const sortedRows = sort
    ? filteredRows.sort(sortFn(sort, columnIds))
    : filteredRows

  return (
    <Table className={className}>
      <thead>
        <tr>
          {columns.map(({ id, type, label, unit }) => (
            <th key={id} type={type?.split('.')[0]}>
              <InlineMarkdown>{label}</InlineMarkdown>
              {unit && <Unit unit={unit} wrap="true" />}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {sortedRows.map((row, i) => (
          <tr key={i}>
            <th>
              {row.url ? <Link to={row.url}>{row.label}</Link> : row.label}
            </th>
            {row.cellValues.map((cell, j) => (
              <Cell key={j} cellData={cell} />
            ))}
          </tr>
        ))}
      </tbody>
      {footer && (
        <tfoot>
          <tr>
            <th>{footer.label}</th>
            {footer.cellValues.map((cell, j) => (
              <Cell key={j} cellData={cell} />
            ))}
          </tr>
        </tfoot>
      )}
    </Table>
  )
}

export default SimpleTable
