import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Helmet } from 'react-helmet-async'
import { Page as PageWrapper } from 'components/Layout'
import { EventsProvider, useEvents } from 'providers/Events'
import Header from 'components/Header'
import Menu from './Menu'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  overflow: hidden;
`

const Main = styled.main`
  position: relative;
  padding: 1rem;
  flex-grow: 1;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 8px inset ${theme('colors.shadow')};
`

const Title = styled.h1`
  text-align: center;
  margin: 3rem 0 2rem;
`

const InnerPage = ({ title, children }) => {
  const { fire } = useEvents()
  return (
    <PageWrapper onClick={() => fire('click')}>
      <Helmet>
        <title>{title ?? 'Loading...'} | Sciigo</title>
      </Helmet>
      <Header />
      <Wrapper>
        <Menu />
        <Main>
          {title && <Title>{title}</Title>}
          {children}
        </Main>
      </Wrapper>
    </PageWrapper>
  )
}

const Page = ({ title, children }) => (
  <EventsProvider>
    <InnerPage title={title}>{children}</InnerPage>
  </EventsProvider>
)

export default Page
