import styled, { useTheme } from 'styled-components'
import { DonutChart as UnstyledDonutChart } from 'components/Chart'

const Wrapper = styled.div`
  min-width: 0; // Hack to make chart resize properly
`

const DonutChart = styled(UnstyledDonutChart)`
  max-height: 15rem;
`

const Pie = ({ values, className }) => {
  const theme = useTheme()

  const data = {
    labels: ['Uppfyller krav', 'Uppfyller ej krav', 'Data saknas'],
    datasets: [
      {
        data: values,
        backgroundColor: [
          theme.colors.brand,
          theme.colors.black,
          theme.colors.medium,
        ],
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        align: 'start',
      },
      datalabels: {
        display: true,
        color: theme.colors.white,
        formatter: val => (val ? `${(100 * val).toFixed()}%` : ''),
      },
    },
  }

  return (
    <Wrapper className={className}>
      <h3>Datatäckning</h3>
      <DonutChart data={data} options={options} />
    </Wrapper>
  )
}

export default Pie
