import { useTeam, useReport } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import { getValue, getLatestSnapshotId, isReportOpen } from 'utils/data'

import Page from 'components/Page'
import Loader from 'components/Loader'
import Switch from 'components/Switch'
import Debug from 'components/Debug'

import {
  StaticSections,
  StaticSection,
  DocumentsSection,
} from 'components/Section'
import { useSnapshot } from 'providers/Session'

const viewModel = {
  summary: {
    label: 'Sammanfattande kommentarer',
    keys: ['reportComment', 'reporterComment', 'futurePlansComment'],
  },
}

const ReportReview = ({ match }) => {
  const { id: reportId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const {
    data: report,
    loading: loadingReport,
    saveProperty,
    saveListItem,
  } = useReport(reportId, teamData)

  const snapshotId = getLatestSnapshotId(report)

  const { data: snapshotData, loading: loadingSnapshot } = useSnapshot(
    getValue(report?.reporter),
    snapshotId
  )
  const loading = loadingTeam || loadingReport || loadingSnapshot

  if (teamData?.teamData && teamData.teamData.type !== 'creditor') {
    // Todo: proper 404 page later
    throw new Error('Route is not accessible to reporter')
  }

  useRecent('report', reportId, report?.title.displayValue)

  const save = async ({ id, value, document, comment }) =>
    saveProperty(id, { value, document, comment })

  const saveItem = async ({ id, itemId, value, document, comment }) =>
    saveListItem(id, itemId, { value, document, comment })

  const snapshot = snapshotData
    ? {
        organization: Object.values(snapshotData.organizations)[0],
        ...snapshotData,
      }
    : {}

  const propertySpecificComments = Object.entries(
    snapshot.properties ?? {}
  ).map(([id, prop]) => ({
    id,
    label: prop.propertyId.displayValue,
    description: `Specifika kommentarer angående ${prop.propertyId.displayValue}`,
    type: 'textArea',
    editable: true,
    sourced: true,
    ...(report.propertySpecificComments.items?.[id] ?? {}),
  }))

  return (
    <Page title={report?.title.displayValue}>
      {loading ? (
        <Loader />
      ) : snapshot ? (
        <>
          <Switch
            labels={['Data', 'Kommentarer', 'Diskussion']}
            urls={[
              `/report/${reportId}`,
              `/report/${reportId}/review`,
              `/report/${reportId}/discussion`,
            ]}
            activeIndex={1}
          />
          <StaticSections
            viewModel={viewModel}
            data={report}
            editable={isReportOpen(report)}
            save={save}
          />
          <StaticSection
            title="Fastighetsspecifika kommentarer"
            attributes={propertySpecificComments}
            documents={report.documents?.items}
            editable={isReportOpen(report)}
            save={({ id, value, document }) =>
              saveItem({
                id: 'propertySpecificComments',
                itemId: id,
                value,
                document,
              })
            }
          />

          <DocumentsSection
            key="documents"
            title="Dokument"
            documents={report.documents}
            parent={report}
            save={saveItem}
            editable={isReportOpen(report)}
          />
        </>
      ) : (
        <div></div>
      )}
      <Debug value={report} />
    </Page>
  )
}

export default ReportReview
