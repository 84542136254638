import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Icon from 'components/Icon'
import Card from 'components/Card'
import Markdown from 'components/Markdown'
import KpiTable from './KpiTable'
import { getPropertyKpis } from './kpis'
import Pie from './Pie'

const Wrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: start;

  > div {
    min-width: 0;
  }
`

const PropTitleLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: middle;
  svg {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    margin-right: 0.5rem;
  }
  h2 {
    margin: 0;
  }
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Review = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`

const ReviewComment = styled(Markdown)``

const PropertyReport = ({ property, report, qualificationResults }) => {
  const result = qualificationResults.properties[property.id]

  return (
    <Card title={property.propertyId.displayValue}>
      <Wrapper>
        <div>
          <PropTitleLink to={`/report/${report.id}/property/${property.id}`}>
            <Icon type="building" />
            <h2>{property.propertyId.displayValue}</h2>
          </PropTitleLink>
          <KpiTable items={Object.values(getPropertyKpis(property))} />
        </div>
        <div>
          <Pie values={[result.progress, 0, 1 - result.progress]} />
        </div>
        <Review>
          <h3>Rådgivarens Kommentarer</h3>
          <ReviewComment>
            {report.propertySpecificComments.items?.[property.id]
              ?.displayValue ?? '(Inga kommentarer)'}
          </ReviewComment>
        </Review>
      </Wrapper>
    </Card>
  )
}

export default PropertyReport
