import { FieldPath } from 'utils/firebase'
import { useTeamProperty, useTeamPropertyMulti } from './utils'

export const usePlan = (id, teamData) => useTeamProperty(id, teamData, 'plan')

export const usePlans = (ids, teamData) =>
  useTeamPropertyMulti(
    ids?.length > 0 ? [FieldPath.documentId(), 'in', ids] : undefined,
    teamData,
    'plan'
  )
