import { useMemo } from 'react'
import ChartJS from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { defaultsDeep } from 'lodash'
import { getLightened } from 'components/Chart/colors'
import { theme } from 'theme'
import { Img } from './elements'

const defaultOptions = (title, subtitle, primaryColor, data) => {
  const numberOfDatasets = data.datasets?.length ?? 0
  const colors = getLightened(primaryColor, numberOfDatasets)
  return {
    maintainAspectRatio: false,
    responsive: false,
    animation: false,
    borderColor: (_, { color }) => color,
    backgroundColor: (_, { color }) => color,
    plugins: {
      title: {
        display: (_, { text }) => !!text,
        text: title,
        font: {
          size: ChartJS.defaults.font.size * 2,
        },
        padding: {
          bottom: ChartJS.defaults.font.size,
        },
      },
      subtitle: {
        display: (_, { text }) => !!text,
        text: subtitle,
        font: {
          size: ChartJS.defaults.font.size * 1.5,
        },
        padding: {
          bottom: ChartJS.defaults.font.size,
        },
      },
      legend: {
        position: 'top',
        display: numberOfDatasets > 1,
      },
    },
    color: ({ datasetIndex }) => colors[datasetIndex],
  }
}

const render = ({
  type,
  data,
  options,
  title,
  subtitle,
  plugins = [],
  width = 900,
  height = 600,
  fontSize = 25,
}) => {
  const defaultFontSize = ChartJS.defaults.font.size
  ChartJS.defaults.font.size = (fontSize * width) / 800

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const config = {
    type,
    plugins: [...plugins],
    options: defaultsDeep(
      {
        responsive: false,
        animation: false,
      },
      options,
      type === 'pie'
        ? {
            borderWidth: ChartJS.defaults.font.size / 5,
            borderColor: 'white',
            radius: ctx => 100 - ctx.datasetIndex * 5 + '%',
            cutout: '50%',
            plugins: {
              legend: {
                display: true,
                position: 'right',
                align: 'start',
                type: 'rect',
              },
            },
          }
        : {},
      defaultOptions(title, subtitle, theme.colors.primary, data)
    ),
    data,
  }

  const chart = new ChartJS(canvas, config)
  const src = chart.toBase64Image()
  ChartJS.defaults.font.size = defaultFontSize

  return src
}

const Chart = ({
  style,
  type,
  data,
  options,
  title,
  subtitle,
  plugins = [],
  width,
  height,
}) => {
  const src = useMemo(
    () =>
      render({
        type,
        data,
        options,
        title,
        subtitle,
        plugins: type === 'pie' ? [ChartDataLabels, ...plugins] : plugins,
        width,
        height,
        fontSize: style?.fontSize,
      }),
    [] //eslint-disable-line react-hooks/exhaustive-deps
  )
  return <Img src={src} style={style} />
}

export default Chart
