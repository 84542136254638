import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { isFunction } from 'lodash'
import { useField, useFormikContext, FieldArray } from 'formik'
import { isInteger, isIntegerBetween } from 'utils/validation'
import Markdown, { InlineMarkdown } from 'components/Markdown'
import Unit from 'components/Unit'
import { NakedButton } from 'components/Button'

import Options from './Options'
import Select from './Select'
import Checkbox from './Checkbox'
import TextArea from './TextArea'
import Amount from './Amount'
import FileInput from './FileInput'
import { getInitialValue } from './utils'

const validators = {
  int: isInteger,
  year: isIntegerBetween(1800, 2200),
}

const Wrapper = styled.div`
  position: relative;
  & + & {
    margin-top: 0.5rem;
  }
`

const Description = styled(Markdown)`
  font-style: italic;
  font-size: 0.8em;
`

const Label = styled.label`
  font-size: 1rem;
  line-height: 1.3;
  *:not(sup):not(sub) {
    font-size: 1rem;
  }
  > ${Description} {
    font-size: 0.9em;
  }
  padding-left: 0.5rem;
`

const Legend = styled.legend`
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
`

const ErrorMessage = styled.p`
  padding-left: 0.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
  height: 1rem;
  text-align: left;
  margin: 0.3rem 0;
  color: ${ifProp('touched', theme('colors.error'), '')};
`

export const FieldSet = styled.fieldset`
  border: none;
  padding: 0.7rem;
  margin: 0;
  border: 1px solid #eee;
  border-radius: 4px;

  ${Label} {
    font-size: 1rem;
  }
`

const StyledInput = styled.input`
  &[disabled] {
    background-color: transparent;
  }
  ::placeholder {
    color: ${theme('colors.medium')};
  }
`

const getType = type => {
  if (['int', 'float', 'percentage', 'year'].includes(type)) {
    return 'number'
  } else if (type?.split('.')[0] === 'number') {
    return 'number'
  }
  return type
}

export const FakeFormField = ({ className, label, unit, children }) => (
  <Wrapper className={className}>
    {label && (
      <Label>
        <InlineMarkdown>{label}</InlineMarkdown>
        {unit && <Unit unit={unit} wrap="true" />}
      </Label>
    )}
    {children}
  </Wrapper>
)

const FormField = ({
  className,
  inputRef,
  label,
  unit,
  description,
  name,
  type,
  multiple,
  placeholder,
  disabled,
  options,
  labels,
  isCreatable,
  fields,
  env,
  validate,
  tabIndex,
  autoFocus,
}) => {
  const { setSubmitting } = useFormikContext()
  const [field, meta, { setError, setTouched }] = useField({
    name,
    validate: validate ?? validators[type],
  })

  if (type === 'section') {
    return (
      <Section
        {...{
          name,
          field,
          label,
          unit,
          fields,
          disabled,
          className,
          autoFocus,
        }}
      />
    )
  }

  if (type === 'fieldArray') {
    return (
      <FieldArraySection
        {...{
          name,
          field,
          label,
          unit,
          fields,
          disabled,
          className,
          autoFocus,
        }}
      />
    )
  }

  return (
    <Wrapper className={className}>
      {label && type !== 'checkbox' && (
        <Label htmlFor={name}>
          <InlineMarkdown>{label}</InlineMarkdown>
          {unit && <Unit unit={unit} wrap="true" />}
          {description && <Description>{description}</Description>}
        </Label>
      )}
      {type === 'options' ? (
        <Options
          options={options}
          {...field}
          isMulti={Array.isArray(meta.initialValue) || multiple}
          disabled={disabled}
        />
      ) : type === 'select' ? (
        <Select
          options={options}
          {...field}
          isCreatable={isCreatable}
          isMulti={Array.isArray(meta.initialValue) || multiple}
          disabled={disabled}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          ref={inputRef}
        />
      ) : type === 'checkbox' ? (
        <Checkbox
          name={name}
          label={label ?? ''}
          disabled={disabled}
          ref={inputRef}
          {...field}
        />
      ) : type === 'textArea' ? (
        <TextArea
          name={name}
          disabled={disabled}
          {...field}
          placeholder={placeholder}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          ref={inputRef}
        />
      ) : type === 'amount' ? (
        <Amount name={name} options={options} disabled={disabled} />
      ) : type === 'boolean' ? (
        <Options
          options={[
            { value: true, label: labels?.[0] ?? 'Ja' },
            { value: false, label: labels?.[1] ?? 'Nej' },
          ]}
          disabled={disabled}
          {...field}
        />
      ) : type === 'file' ? (
        <FileInput
          env={env}
          disabled={disabled}
          onUploadStarted={() => setSubmitting(true)}
          onUploadFinished={() => {
            setTouched(true)
            setSubmitting(false)
          }}
          onError={e => {
            setTouched(true)
            setSubmitting(false)
            setError(e)
          }}
          {...field}
        />
      ) : (
        <StyledInput
          type={getType(type)}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          ref={inputRef}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          {...field}
        />
      )}
      {type !== 'hidden' && (
        <ErrorMessage touched={meta.touched}>{meta.error}</ErrorMessage>
      )}
    </Wrapper>
  )
}

const StyledFormField = styled(FormField)``
export default StyledFormField

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  > ${NakedButton} {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    svg {
      margin-right: 0.5rem;
    }
  }
`

const FieldArrayWrapper = styled.div`
  ${FieldSet} {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    > div {
      width: 40%;
      margin-top: 0;
    }
    > ${NakedButton} {
      margin-left: 0;
    }
  }
`

const getItemVisible = (item, value) =>
  isFunction(item.visible) ? item.visible(value) : item.visible !== false

const getItemOptions = (item, value) =>
  isFunction(item.options) ? item.options(value) : item.options

const Section = ({
  name,
  field,
  label,
  unit,
  fields,
  disabled,
  className,
  autoFocus,
}) => (
  <Wrapper className={className}>
    {label && (
      <Legend>
        <InlineMarkdown>{label}</InlineMarkdown>
        {unit && <Unit unit={unit} wrap="true" />}
      </Legend>
    )}
    <FieldSet>
      {fields &&
        Object.entries(fields).map(([key, item], idx) =>
          getItemVisible(item, field.value) ? (
            <FormField
              className={className}
              key={key}
              label={item.label}
              placeholder={item.placeholder}
              unit={item.unit}
              name={`${name}.${key}`}
              type={item.type}
              description={item.description}
              options={getItemOptions(item, field.value)}
              disabled={disabled}
              validate={item.validate}
              autoFocus={idx === 0 && autoFocus}
            />
          ) : null
        )}
    </FieldSet>
  </Wrapper>
)

const FieldArraySection = ({
  name,
  field,
  label,
  unit,
  fields,
  disabled,
  className,
  autoFocus,
}) => (
  <Wrapper className={className} type="fieldArray">
    {label && (
      <Legend>
        <InlineMarkdown>{label}</InlineMarkdown>
        {unit && <Unit unit={unit} wrap="true" />}
      </Legend>
    )}
    <FieldArray name={name}>
      {arrayHelpers => (
        <FieldArrayWrapper>
          {field.value.map((key, index) => (
            <FieldSet key={index}>
              {fields &&
                Object.entries(fields).map(([key, item], idx) =>
                  getItemVisible(item, field.value) ? (
                    <FormField
                      className={className}
                      key={key}
                      label={item.label}
                      placeholder={item.placeholder}
                      unit={item.unit}
                      name={`${name}.${index}.${key}`}
                      type={item.type}
                      description={item.description}
                      options={getItemOptions(item, field.value)}
                      disabled={disabled}
                      validate={item.validate}
                      autoFocus={idx === 0 && autoFocus}
                    />
                  ) : null
                )}
              <NakedButton
                icon="close"
                onClick={e => arrayHelpers.remove(index)}
              />
            </FieldSet>
          ))}
          <ButtonRow>
            <NakedButton
              icon="plus"
              onClick={e =>
                arrayHelpers.push(
                  getInitialValue({ type: 'section', section: fields })
                )
              }
            >
              Lägg till {fields.key.label}
            </NakedButton>
          </ButtonRow>
        </FieldArrayWrapper>
      )}
    </FieldArray>
  </Wrapper>
)
