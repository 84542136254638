import { Font } from '@react-pdf/renderer'

import light from './fonts/JosefinSans-Light.ttf'
import regular from './fonts/JosefinSans-Regular.ttf'
import medium from './fonts/JosefinSans-Medium.ttf'
import semibold from './fonts/JosefinSans-SemiBold.ttf'

// import Bold from './fonts/OpenSans-Bold.ttf'
// import BoldItalic from './fonts/OpenSans-BoldItalic.ttf'
// import ExtraBold from './fonts/OpenSans-ExtraBold.ttf'
// import ExtraBoldItalic from './fonts/OpenSans-ExtraBoldItalic.ttf'
import Light from './fonts/Open Sans Light.ttf'
import Medium from './fonts/Open Sans Medium.ttf'
import Regular from './fonts/Open Sans Regular.ttf'
import SemiBold from './fonts/Open Sans SemiBold.ttf'

import LightItalic from './fonts/Open Sans Light Italic.ttf'
import Italic from './fonts/Open Sans Italic.ttf'
import MediumItalic from './fonts/Open Sans Medium Italic.ttf'
import SemiBoldItalic from './fonts/Open Sans SemiBold Italic.ttf'

Font.register({
  family: 'Josefin Sans',
  fonts: [
    { src: light, fontWeight: 300 },
    { src: regular, fontWeight: 400 },
    { src: medium, fontWeight: 500 },
    { src: semibold, fontWeight: 600 },
  ],
})

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: Regular, fontWeight: 400 },
    { src: Italic, fontWeight: 400, fontStyle: 'italic' },
    { src: Light, fontWeight: 300 },
    { src: LightItalic, fontWeight: 300, fontStyle: 'italic' },
    { src: Medium, fontWeight: 500 },
    { src: MediumItalic, fontWeight: 500, fontStyle: 'italic' },
    { src: SemiBold, fontWeight: 600 },
    { src: SemiBoldItalic, fontWeight: 600, fontWeigth: 'italic' },
  ],
})

Font.registerHyphenationCallback(word => [word])
