import { theme } from 'theme'
import ChartJS from 'chart.js/auto'
import Chart from 'components/Pdf/Chart'

const Coverage = ({ data }) => (
  <Chart
    type="pie"
    data={{
      labels: ['Uppfyller krav', 'Uppfyller ej krav', 'Data saknas'],
      datasets: [
        {
          data,
          backgroundColor: [
            theme.colors.brand,
            theme.colors.black,
            theme.colors.medium,
          ],
        },
      ],
    }}
    options={{
      borderWidth: ChartJS.defaults.font.size / 10,
      borderColor: 'white',
      radius: ctx => 100 - ctx.datasetIndex * 5 + '%',
      cutout: '50%',
      plugins: {
        legend: {
          display: true,
          position: 'right',
          align: 'start',
          type: 'rect',
          labels: {
            font: {
              size: () => ChartJS.defaults.font.size,
            },
          },
        },
        datalabels: {
          font: () => ({
            size: ChartJS.defaults.font.size,
          }),
          display: true,
          color: theme.colors.white,
          formatter: val => (val ? `${(100 * val).toFixed()}%` : ''),
        },
      },
    }}
  />
)

export default Coverage
