import { useMemo } from 'react'
import { useTeam, useReport, useSnapshot } from 'providers/Session'
import config from 'config'
import Loader from 'components/Loader'
import Page from 'components/Page'
import { StaticSections, DocumentsSection } from 'components/Section'
import { getLatestSnapshotId, getValue } from 'utils/data'
import { qualifyObject } from 'qualifier'

const viewModel = {
  general: {
    label: 'Organisation och verksamhet',
    keys: [
      'name',
      'orgNr',
      'activityDescription',
      'mainActivity',
      'otherActivities',
      // 'geographies',
      // 'taxRates',
    ],
  },
  sustainabilityWork: {
    label: 'Hållbarhetsarbete',
    keys: ['sustainabilityProcessDescription'],
  },
  minimumSafeguards: {
    label: 'Minimiskyddsåtgärder (Minimum Safeguards)',
    keys: [
      'humanRightsPolicy',
      'dueDiligenceProcess',
      'processToTakeAction',
      'trackingProcess',
      'publicCommunication',
    ],
  },
}

const useOrganization = ({ orgId, reportId }, teamData) => {
  const { loading: loadingReport, data: reportData } = useReport(
    reportId,
    teamData
  )

  const snapshotId = getLatestSnapshotId(reportData)
  const reporterId = reportData?.reporter.value

  const { loading: loadingSnapshot, data: snapshotData } = useSnapshot(
    reporterId,
    snapshotId,
    reportId
  )

  const loading = reportId
    ? loadingReport || loadingSnapshot
    : !teamData.organizations
  const data = loading
    ? undefined
    : (reportId ? snapshotData : teamData)?.organizations[orgId]

  return {
    loading,
    data,
    reporter: reportData ? getValue(reportData.reporter) : undefined,
  }
}

const OrganizationDetails = ({ match }) => {
  const { reportId, orgId } = match.params
  const {
    loading: loadingTeam,
    saveProperty,
    saveListItem,
    ...teamData
  } = useTeam()
  const {
    data: orgData,
    reporter,
    loading: loadingOrg,
  } = useOrganization({ orgId, reportId }, teamData)

  const loading = loadingTeam || loadingOrg

  const save = ({ id, value, document, comment }) =>
    saveProperty('organization', orgId, id, { value, document, comment })

  const saveItem = async ({ id, itemId, value, document, comment }) =>
    saveListItem('organization', orgId, id, itemId, {
      value,
      document,
      comment,
    })

  const { missing, inadequate } = useMemo(() => {
    return (
      qualifyObject(orgId, 'organization', orgData, teamData) ?? {
        missing: {},
        inadequate: {},
      }
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(orgData), teamData])

  return (
    <Page title={orgData?.name?.displayValue}>
      {loading ? (
        <Loader />
      ) : orgData ? (
        <>
          <StaticSections
            viewModel={viewModel}
            data={orgData}
            editable={!reportId}
            save={save}
            filePath={reporter}
            qualificationResults={{ missing, inadequate }}
          />
          <DocumentsSection
            key="documents"
            title="Dokument"
            documents={orgData.documents}
            parent={orgData}
            save={saveItem}
            editable={!reportId}
            filePath={reporter}
          />
        </>
      ) : (
        <div></div>
      )}
      {orgData && config.debug && (
        <>
          <hr />
          <pre>
            {JSON.stringify(
              orgData,
              (key, value) => (key === 'options' ? '' : value),
              2
            )}
          </pre>
        </>
      )}
    </Page>
  )
}

export default OrganizationDetails
