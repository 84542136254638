import styled from 'styled-components'
import { theme } from 'styled-tools'
import { isFunction } from 'lodash'

const ProgressBar = styled(({ className, progress }) => (
  <div className={className}>
    <div></div>
  </div>
))`
  position: relative;
  border: 1px solid ${theme('colors.primary')};
  height: 1.5rem;
  border-radius: 4px;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress * 100 ?? 0}%;
    background-color: ${theme('colors.primary')};
    bottom: 0;
    z-index: 0;
  }
`

const Result = styled.div`
  font-weight: 500;
  color: ${theme('colors.success')};
  margin-left: 0.1rem;
`

const ErrorMessage = styled.div`
  color: ${theme('colors.error')};
`

const UnstyledStatus = ({ className, data = {}, successMessage }) => {
  const { progress, done, error, result } = data
  return (
    <div className={className}>
      {!done && !error && progress && <ProgressBar progress={progress} />}
      {done && (
        <Result>
          {isFunction(successMessage) ? successMessage(result) : successMessage}
        </Result>
      )}
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

const Status = styled(UnstyledStatus)``

export default Status
