import { Link as UnstyledLink } from 'react-router-dom'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { useUser, useTeam } from 'providers/Session'
import { useMailbox } from 'providers/Messaging'

const MenuWrapper = styled.menu`
  width: 25vw;
  min-width: 18rem;
  max-width: 25rem;
  background: white;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  padding-bottom: 3rem;
  text-align: left;
  overflow-y: auto;
  h2 {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.65rem 0.65rem 0.45rem;
    background: ${theme('colors.primaryMediumLight')};
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: white;
    margin: 0;
  }
`

const Link = styled(UnstyledLink)`
  display: block;
  color: ${theme('colors.primaryText')};
  text-transform: ${ifProp('raw', 'none', 'capitalize')};
  border-bottom: 1px solid ${theme('colors.border')};
  padding: 0.65rem 1.3rem;
  font-weight: 400;
  &:hover {
    text-decoration: none;
    background: ${theme('colors.primaryThin')};
  }
`

const NotALink = styled.div`
  display: block;
  color: ${theme('colors.primaryText')};
  border-bottom: 1px solid ${theme('colors.border')};
  padding: 0.65rem 1.3rem;
  font-weight: 400;
`

const MAX_RECENT = 4
const getRecent = data =>
  Object.entries(data ?? {})
    .sort(([id1, { timestamp: t1 }], [id2, { timestamp: t2 }]) => t2 - t1)
    .map(([id, { label }]) => ({ id, label }))
    .slice(0, MAX_RECENT)

const Recent = ({ type, emptyLabel, alwaysList }) => {
  const { recent: allRecent, team } = useUser()
  const recent = getRecent(allRecent?.[team]?.[type] ?? {})
  return recent.length ? (
    [
      ...recent.map(({ id, label }) => (
        <Link key={id} to={`/${type}/${id}`}>
          {label}
        </Link>
      )),
      <Link key="list" to={`/${type}/list`}>
        ...Alla
      </Link>,
    ]
  ) : alwaysList ? (
    [
      <Link key="list" to={`/${type}/list`}>
        ...Alla
      </Link>,
      <NotALink key="empty">({emptyLabel})</NotALink>,
    ]
  ) : (
    <NotALink>({emptyLabel})</NotALink>
  )
}

const ReporterMenu = () => {
  const { organizations } = useTeam()
  const { inbox } = useMailbox()
  const unread = inbox ? inbox.filter(message => !message.read).length : 0

  return (
    <MenuWrapper>
      <Link to="/">Hem</Link>
      <Link to="/messages">Meddelanden {unread ? `(${unread})` : null}</Link>
      <h2>Ärenden</h2>
      <Recent type="report" emptyLabel="Inga ärenden" />
      <h2>Organisationer</h2>
      {Object.entries(organizations).map(([id, orgData]) => (
        <Link key={id} to={`/organization/${id}`}>
          {orgData.name.displayValue}
        </Link>
      ))}
      <h2>Fastigheter</h2>
      <Recent type="property" emptyLabel="Inga fastigheter" />
      <h2>Planer</h2>
      <Recent type="plan" emptyLabel="Inga planer" />
      <h2>Kreditgivare</h2>
      <Recent
        type="creditor"
        emptyLabel="Inga kreditgivare"
        alwaysList={true}
      />
    </MenuWrapper>
  )
}

const CreditorMenu = props => {
  const { inbox } = useMailbox()
  const unread = inbox ? inbox.filter(message => !message.read).length : 0

  return (
    <MenuWrapper>
      <Link to="/">Hem</Link>
      <Link to="/messages">Meddelanden {unread ? `(${unread})` : null}</Link>
      <h2>Ärenden</h2>
      <Recent type="report" emptyLabel="Inga ärenden" />
      <h2>Kunder</h2>
      <Recent type="reporter" emptyLabel="Inga kunder" alwaysList={true} />
    </MenuWrapper>
  )
}

const Menu = props => {
  const { teamData } = useTeam()
  return teamData?.type === 'creditor' ? (
    <CreditorMenu {...props} />
  ) : teamData ? (
    <ReporterMenu {...props} />
  ) : (
    <MenuWrapper />
  )
}

export default Menu
