import { countBy, pickBy, mapValues } from 'lodash'
import { getValue, propAverage } from 'utils/data'

const propertyTypeCategories = {
  residential: 'Bostäder',
  residentialAndCommercial: 'Bostäder och lokaler',
  commercial: 'Lokaler',
  industrial: 'Industrifastigheter',
  other: 'Övrigt',
}

const formatNumber = n =>
  parseFloat(n)
    .toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replaceAll(',', ' ')

const getPropertyTypeCategory = propertyType =>
  [313, 320].includes(propertyType)
    ? 'residential'
    : propertyType === 321
    ? 'residentialAndCommercial'
    : [322, 323, 324, 325, 326].includes(propertyType)
    ? 'commercial'
    : propertyType > 411 && propertyType <= 499
    ? 'industrial'
    : 'other'

const getNumberOfPropertiesByType = properties =>
  countBy(Object.values(properties), prop =>
    getPropertyTypeCategory(parseFloat(getValue(prop.propertyType)))
  )

export const getSummaryKpis = properties => {
  const propsByType = getNumberOfPropertiesByType(properties)

  return {
    numberOfProperties: {
      label: 'Antal fastigheter',
      value: Object.keys(properties).length,
    },
    ...pickBy(
      mapValues(propertyTypeCategories, (label, key) => ({
        label,
        value: propsByType[key],
        indent: 1,
      })),
      ({ value }) => value
    ),
    yearOfConstruction: {
      label: 'Byggår (snitt)',
      value: Math.round(propAverage(properties, 'yearOfConstruction')),
    },
    floorArea: {
      label: 'Yta (snitt)',
      value: `${formatNumber(propAverage(properties, 'floorArea'))} m^{2}`,
    },
  }
}

export const getPropertyKpis = property => {
  return mapValues(
    {
      propertyType: {
        label: 'Fastighetstyp',
        value: getPropertyTypeCategoryLabel(property),
      },
      yearOfConstruction: {},
      yearOfModification: {},
      floorArea: {},
      tempArea: {},
      energyClass: {},
      primaryEnergyDemand: {},
      specificEnergyDemand: {},
      mainHeatingType: {},
      epcExpirationDate: {},
      combinedHvacOutput: {},
      automatedHvacControlSystem: {},
      certifications: {
        value: property.certifications.items?.length
          ? property.certifications.items
              .map(({ displayValue }) => displayValue)
              .join('\n')
          : '—',
      },
    },
    ({ label, value }, key) => ({
      label: label ?? property[key].label ?? key,
      value: value ?? property[key].displayValue ?? '—',
    })
  )
}

export const getPropertyTypeCategoryLabel = property =>
  propertyTypeCategories[
    getPropertyTypeCategory(parseFloat(getValue(property.propertyType)))
  ]
