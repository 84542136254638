import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'

import { useTeam, requestReport } from 'providers/Session'

import FormPopup, { Errors, ButtonRow } from 'components/Popup/FormPopup'
import { wrapSubmit } from 'utils/form'

const NewPropertyButton = ({ reporterId }) => {
  return (
    <FormPopup buttonStyle="normal" label="Nytt förvärv">
      <NewPropertyForm title="Nytt förvärv" reporterId={reporterId} />
    </FormPopup>
  )
}

const NewPropertyForm = ({ reporterId, close }) => {
  const { id: teamId } = useTeam()
  const submit = data =>
    requestReport({
      teamId,
      reporterId,
      type: 'acquisition',
      data,
    })

  return (
    <Formik
      initialValues={{ propertyId: '' }}
      onSubmit={wrapSubmit(submit, close, { timeout: 1000 })}
    >
      {({ isSubmitting, values, errors, status, setValues }) => (
        <Form>
          <h2>Nytt förvärv</h2>
          <FormField
            name="propertyId"
            label="Fastighetsbeteckning"
            description="Kommun Trakt Block:Enhet"
            placeholder="Kommun Trakt Block:Enhet"
            autoFocus={true}
          />
          <Errors errors={errors} format={formatError} />
          <ButtonRow
            disabled={isSubmitting || !values.propertyId}
            loading={isSubmitting}
            title="Skicka"
            done={status?.done}
            successMessage="Ärendeförfrågan skickad"
          />
        </Form>
      )}
    </Formik>
  )
}

export default NewPropertyButton

const formatError = ({ code, message }) =>
  code === 'invalid-argument' ? 'Felaktig fastighetsbeteckning' : message
