import React from 'react'
import { StyleSheet } from '@react-pdf/renderer'
import { View, Text } from './elements'

const styles = StyleSheet.create({
  ol: {
    marginBottom: '0.5em',
  },
  ul: {
    marginBottom: '0.5em',
  },
  li: {
    display: 'flex',
    flexDirection: 'row',
  },
  bullet: {},
})

export const Ul = ({ style, children, ...props }) => (
  <View style={[styles.ul, style]} {...props}>
    {React.Children.map(children, (child, i) => (
      <View style={[styles.li, style]}>
        <Text style={styles.bullet}> •</Text>
        {child}
      </View>
    ))}
  </View>
)

export const Li = ({ style, children, ...props }) => <Text>{children}</Text>

export const Ol = ({ children, style, ...props }) => {
  return (
    <View style={[styles.ol, style]}>
      {React.Children.map(children, (child, i) => (
        <View style={styles.li}>
          <Text style={styles.bullet}> {i + 1}.</Text>
          {child}
        </View>
      ))}
    </View>
  )
}
