import { getFirebase, FieldPath } from 'utils/firebase'
import { nanoid } from 'utils/data'
import { useTeamProperty, useTeamPropertyMulti } from './utils'

export const useProperty = (propertyId, teamData) => {
  const { saveProperty, saveListItem, ...state } = useTeamProperty(
    propertyId,
    teamData,
    'property'
  )

  const addPlan = async () => {
    const { db } = getFirebase()
    const planId = nanoid()
    if (!teamData.id) {
      throw new Error('Team data is stale')
    }
    if (!state.data?.propertyId) {
      throw new Error('Property data is stale')
    }

    const planTitle = `Plan för ${state.data.propertyId.displayValue}`

    const batch = db.batch()
    batch.set(
      db.doc(`teams/${teamData.id}/properties/${propertyId}`),
      {
        plans: {
          items: {
            [planId]: {
              value: planId,
            },
          },
        },
      },
      { merge: true }
    )

    batch.set(db.doc(`teams/${teamData.id}/plans/${planId}`), {
      title: {
        value: planTitle,
      },
      property: {
        value: propertyId,
      },
    })

    await batch.commit()
  }

  return {
    saveProperty,
    saveListItem,
    addPlan,
    ...state,
  }
}

export const useProperties = teamData =>
  useTeamPropertyMulti(
    [FieldPath.documentId(), '!=', 'dontexist'],
    teamData,
    'property'
  )
