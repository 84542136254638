import { tint, transparentize, shade } from 'polished'

// const primary = ['#97D374', '#60C425', '#3EA600', '#328700', '#235E00']
// const secondary = ['#578399', '#216A8E', '#065378', '#034362', '#012E44']
// const tertiary = ['#f1f184', '#e0de2b', '#bebd00', '#9b9a00', '#6c6b00']

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  red: '#EB2638',
  other: '#AC3734',
  orange: '#e18841',
  green: '#235E00',
  blue: '#034362',
  yellow: '#FFB',
}

const base = {
  primary: colors.green,
  secondary: colors.blue,
  ...Object.fromEntries(
    ['Thin', 'Light', 'MediumLight', 'Medium'].flatMap((suffix, i) => [
      [`primary${suffix}`, tint(0.9 - i / 4, colors.green)],
      [`secondary${suffix}`, tint(0.9 - i / 4, colors.blue)],
    ])
  ),
}

const contextualColors = {
  dark: tint(0.15, colors.black),
  mediumDark: tint(0.3, colors.black),
  medium: tint(0.5, colors.black),
  mediumLight: tint(0.7, colors.black),
  light: tint(0.9, colors.black),

  primaryText: tint(0.15, colors.black),
  background: tint(0.97, colors.green),

  border: tint(0.9, colors.black),
  borderLight: tint(0.93, colors.black),
  borderPrimary: tint(0.8, colors.green),

  shadow: transparentize(0.9, colors.black),
  success: '#55DD22',
  brand: '#00a800',
  warning: shade(0.5, colors.red),
  error: colors.red,
  highlight: colors.yellow,
}

const energyClassColors = {
  energyClass: {
    A: '#00A351',
    B: '#5FB940',
    C: '#D1D800',
    D: '#FFED00',
    E: '#FDC400',
    F: '#F27C00',
    G: '#EB2416',
  },
}

const theme = {
  typography: {
    fontFamily: `Open Sans, sans-serif`,
    fontSize: '0.8rem',
  },
  sizes: {
    sm: '100%',
    md: '1024px',
    lg: '1024px',
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  gutterWidth: '16px',
  colors: {
    ...colors,
    ...base,
    ...contextualColors,
    ...energyClassColors,
  },
}

export default theme
