import styled from 'styled-components'
import { switchProp } from 'styled-tools'
import { Formik, Form } from 'formik'
import { mapValues, pickBy } from 'lodash'
import { nanoid, toOptions } from 'utils/data'
import { toTimestamp } from 'utils/firebase'
import { useUser } from 'providers/Session'
import certificationTypes from 'data/certificationTypes'
import FormField, { FieldSet } from 'components/form/FormField'
import Button from 'components/Button'

const StyledForm = styled(Form)`
  padding: 3rem 3rem 1.2rem;
  // margin-top: -2.8rem;
  ${FieldSet}, ${FormField} {
    margin-bottom: 1rem;
  }

  ${FieldSet} ${FormField} {
    margin-bottom: 0;
  }
`

const Title = styled.h3`
  margin-bottom: 1.5rem;
`

const StyledFormField = styled(FormField)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  > label {
    padding-left: 0;
    margin-bottom: 0;
    margin-right: 1rem;
    width: auto;
    min-width: 20%;
    font-size: 1rem;
    flex-shrink: 0;
    flex-grow: 0;
  }
  > div {
    flex-grow: 1;
    flex-shrink: 0;
  }
  > p {
    display: none;
  }

  margin-left: ${switchProp('type', { file: '20%' })};
`
const StyledButton = styled(Button)``

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  ${StyledButton} {
    margin-right: 0;
  }
`

const CertificationForm = ({ className, save, documents, onSave }) => {
  const { team } = useUser()

  const initialValues = {
    type: '',
    level: '',
    dateIssued: '',
    document: '',
  }

  const documentOptions = toOptions({
    '': '—',
    ...mapValues(documents, doc => doc.displayValue),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (
        { type, level, dateIssued, document },
        { setSubmitting, resetForm }
      ) => {
        const id = nanoid()
        await save({
          id: 'certifications',
          itemId: id,
          value: pickBy(
            { type, level, dateIssued: toTimestamp(dateIssued) },
            value => !!value && value !== 'none'
          ),
          document,
        })
        resetForm()
        setSubmitting(false)
        onSave?.()
      }}
    >
      {({ isSubmitting, values, errors, setValues }) => (
        <StyledForm className={className}>
          <Title>Lägg till certifiering</Title>
          <StyledFormField
            name="type"
            label="Typ av Certifiering"
            type="select"
            options={Object.entries(certificationTypes).map(
              ([value, { label }]) => ({ value, label })
            )}
          />
          {certificationTypes[values.type]?.levels && (
            <StyledFormField
              name="level"
              label="Nivå"
              type="select"
              options={Object.entries(
                certificationTypes[values.type].levels
              ).map(([value, label]) => ({ value, label }))}
            />
          )}
          {((values.type && !certificationTypes[values.type]?.levels) ||
            values.level) && (
            <StyledFormField
              name="dateIssued"
              label="Utfärdad datum"
              type="date"
            />
          )}
          {((values.type && !certificationTypes[values.type]?.levels) ||
            values.level) &&
            values.dateIssued && (
              <>
                <StyledFormField
                  name="document"
                  label="Stödjande dokumentation"
                  type="select"
                  options={documentOptions}
                />
                {values.supportingDocument === 'new' && (
                  <StyledFormField
                    name="blob"
                    type="file"
                    env={{ teamId: team }}
                  />
                )}
              </>
            )}
          <Row>
            <StyledButton
              disabled={isSubmitting || Object.keys(errors).length}
              type="submit"
            >
              Spara
            </StyledButton>
          </Row>
        </StyledForm>
      )}
    </Formik>
  )
}

export default CertificationForm
