import { nanoid } from 'utils/data'
import { useUser } from 'providers/Session'
import { download } from 'utils/storage'
import Popup from 'components/Popup'
import { NakedButton } from 'components/Button'
import BaseSection, {
  ToolsWrapper,
  StyledList,
  ItemTitle,
  Empty,
} from './BaseSection'
import PropertyForm from './PropertyForm'
import FileInput from 'components/form/FileInput'

const Tools = ({
  id,
  label,
  description,
  value,
  type,
  unit,
  labels,
  options,
  section,
  sourced,
  canDelete,
  editable,
  filePath,
  save,
  delete: _delete,
}) => {
  const { team } = useUser()

  const downloadDocument = ({ hash, name, type }) => {
    download(`${filePath ?? team}/${hash}`, name, type)
  }

  return (
    <ToolsWrapper>
      <NakedButton icon="download" onClick={e => downloadDocument(value)} />
      {editable && [
        <Popup key={0} icon="pencil">
          <PropertyForm
            id={id}
            label={'Dokumentinformation'}
            type={type}
            value={value}
            section={section}
            sourced={sourced}
            save={save}
          />
        </Popup>,
        <NakedButton
          key={1}
          icon="trash"
          kind="warning"
          onClick={e => _delete(id)}
          disabled={!canDelete}
          title={
            canDelete
              ? 'Ta bort dokument'
              : 'Kan inte ta bort, dokumentet används i en eller flera datakällor'
          }
        />,
      ]}
    </ToolsWrapper>
  )
}

const DocumentsSection = ({
  title,
  documents,
  parent,
  editable = true,
  save,
  filePath,
}) => {
  const { team } = useUser()

  const uploaded = async value => {
    await saveMeta({ id: nanoid(), value })
  }

  const saveMeta = ({ id, value }) => {
    save({ id: 'documents', itemId: id, value })
  }

  const deleteDocument = id => {
    save({ id: `documents`, itemId: id, value: undefined })
  }

  const items = Object.entries(documents?.items ?? {})
    .map(([id, item]) => ({
      id,
      canDelete: editable && !hasDocumentLinks(parent, id),
      ...item,
    }))
    .sort((i1, i2) => i1.displayValue.localeCompare(i2.displayValue, 'sv'))

  return (
    <BaseSection title={title}>
      {items.length === 0 && !(documents?.editable && editable) && (
        <Empty>(Inga dokument)</Empty>
      )}
      <StyledList data={items}>
        <ItemTitle />
        <Tools
          save={saveMeta}
          delete={deleteDocument}
          editable={documents?.editable && editable}
          filePath={filePath}
        />
      </StyledList>
      {documents?.editable && editable && (
        <FileInput
          env={{ teamId: team }}
          onChange={e => uploaded(e.target.value)}
        />
      )}
    </BaseSection>
  )
}

export default DocumentsSection

const hasDocumentLinks = (prop, documentId) =>
  Object.values(prop).some(
    ({ source, items }) =>
      source?.document?.id === documentId ||
      (items &&
        Object.values(items).some(
          ({ source }) => source?.document?.id === documentId
        ))
  )
