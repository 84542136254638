import { Page as PdfPage, StyleSheet } from '@react-pdf/renderer'
import Logo from '../Logo'

const rem = 14
const styles = StyleSheet.create({
  logo: {
    position: 'absolute',
    top: 1.5 * rem,
    left: 2 * rem,
    width: 4 * rem,
  },
  page: {
    padding: `${5 * rem} ${4 * rem}`,
  },
})
const Page = ({ style, children, ...props }) => (
  <PdfPage style={[style, styles.page]} {...props}>
    <Logo style={styles.logo} fixed />
    {children}
  </PdfPage>
)

export default Page
