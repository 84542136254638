import styled from 'styled-components'
import { PDFViewer, pdf } from '@react-pdf/renderer'
import { getSafeFileName } from 'utils/storage'
import ErrorBoundary from 'components/ErrorBoundary'

const StyledViewer = styled(PDFViewer)`
  margin: -1rem;
  width: calc(100% + 2rem);
  height: 100%;
`

export const download = async (doc, name = 'rapport') => {
  const blob = await pdf(doc).toBlob()
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.setAttribute('download', getSafeFileName(name))
  a.click()
}

export const Viewer = props => (
  <ErrorBoundary>
    <StyledViewer {...props} />
  </ErrorBoundary>
)
