import { SetThree12 as colorScheme } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer'
import ChartJS from 'chart.js/auto'
import Chart from 'components/Pdf/Chart'
import { theme } from 'theme'
import { shade } from 'polished'
import { generateLegendLabelFromSeriesConfig } from 'components/Chart/utils'

const ComboChart = ({ labels, datasets }) => (
  <Chart
    type="bar"
    data={{
      labels,
      datasets: datasets.map(set => ({
        yAxisID: set.type === 'line' ? 'yLine' : 'yBar',
        borderWidth:
          set.type === 'line'
            ? ChartJS.defaults.font.size / 1.5
            : ChartJS.defaults.font.size / 25,
        backgroundColor: set.color,
        borderColor:
          set.type === 'line'
            ? 'rgba(3,67,98,0.5)'
            : (_, { color }) => shade(0.1, color),
        ...set,
      })),
    }}
    plugins={[
      {
        beforeInit: chart => {
          const originalFit = chart.legend.fit
          chart.legend.fit = () => {
            originalFit.call(chart.legend)
            chart.legend.height += ChartJS.defaults.font.size * 2
          }
        },
      },
    ]}
    options={{
      plugins: {
        legend: {
          position: 'top',
          align: 'end',
          labels: {
            usePointStyle: true,
            generateLabels: generateLegendLabelFromSeriesConfig,
          },
        },
      },
      datasets: {
        line: {
          color: theme.colors.blue,
        },
      },
      color: colorScheme,
      scales: {
        yBar: {
          beginAtZero: true,
          suggestedMax: 4,
          position: 'left',
          ticks: {
            callback: value => (value % 1 === 0 ? value : undefined),
          },
          afterFit: axis => {
            axis.paddingBottom = ChartJS.defaults.font.size * 6
          },
        },
        yLine: {
          beginAtZero: true,
          suggestedMax: 1000,
          position: 'right',
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    }}
  />
)

export default ComboChart
