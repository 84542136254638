import { useTeamProperty, useTeamPropertyMulti } from './utils'

import { backendCall } from 'utils/firebase'

export const useReports = teamData =>
  useTeamPropertyMulti(['status.value', '!=', 'undefined'], teamData, 'report')

export const useReport = (reportId, teamData) =>
  useTeamProperty(reportId, teamData, 'report')

export const addReport = async ({
  teamId,
  creditor,
  reportId,
  type,
  verified,
}) =>
  backendCall('sendReport', {
    reporter: teamId,
    creditor,
    reportId,
    type,
    verified,
  })

export const requestReport = async ({
  teamId,
  reporterId,
  reportId,
  data,
  type,
}) =>
  backendCall('requestReport', {
    creditor: teamId,
    reporter: reporterId,
    reportId,
    data,
    type,
  })

export const closeReport = async ({ teamId, reportId, result, note }) =>
  backendCall('closeReport', {
    creditor: teamId,
    reportId,
    result,
    note,
  })
