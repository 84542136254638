import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import { getValue } from 'utils/data'
import { closeReport } from 'providers/Session'
import FormPopup, { Errors, ButtonRow } from 'components/Popup/FormPopup'
import { wrapSubmit } from 'utils/form'

const CloseReportButton = ({ report, teamId, disabled, className }) => (
  <FormPopup
    label="Avsluta"
    dialogWidth="20rem"
    buttonStyle="normal"
    className={className}
    disabled={disabled}
  >
    <CloseReportForm report={report} teamId={teamId} />
  </FormPopup>
)

const CloseReportForm = ({ teamId, report, close }) => {
  const submit = ({ result, note }) =>
    closeReport({
      teamId,
      result,
      note,
      reportId: report.id,
    })

  return (
    <Formik
      initialValues={{
        result: getValue(report.status) !== 'signed' ? 'canceled' : 'closed',
        note: '',
      }}
      onSubmit={wrapSubmit(submit, close, { timeout: 1000 })}
    >
      {({ isSubmitting, values, errors, status, setValues }) => (
        <Form>
          <h2>Avsluta ärende</h2>
          <FormField
            name="result"
            label="Anledning"
            type="select"
            options={[
              {
                value: 'closed',
                label: 'Avslutat',
                disabled: getValue(report.status) !== 'signed',
              },
              { value: 'canceled', label: 'Avbrutet' },
            ]}
          />
          {/*<FormField name="note" placeholder="Anteckning" type="textArea" />*/}
          <Errors errors={errors} />
          <ButtonRow
            disabled={isSubmitting}
            loading={isSubmitting}
            title="Avsluta"
            done={status?.done}
            successMessage="Ärendet avslutades"
          />
        </Form>
      )}
    </Formik>
  )
}

export default CloseReportButton
