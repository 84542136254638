import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BarChart as UnstyledBarChart } from 'components/Chart'
import { addReducer, sortByDate } from 'utils/data'
import { dateFromTimestamp } from 'utils/firebase'
import certificationTypes from 'data/certificationTypes'
import { groupBy } from 'lodash'
import SimpleTable from 'components/SimpleTable'

export const title = 'Certifieringsnivå'
const getLastCertification = prop =>
  Object.values(prop.certifications.items ?? {})
    .map(({ value, dateIssued }) => ({
      value,
      dateIssued: dateFromTimestamp(dateIssued),
    }))
    .sort(sortByDate('dateIssued', true))[0] ?? {}

const getCellValues = prop => {
  const lastCert = getLastCertification(prop)
  return [
    prop.tempArea,
    {
      value: lastCert.value?.type,
      displayValue: lastCert.value
        ? certificationTypes[lastCert.value.type].label
        : undefined,
    },
    {
      value: lastCert.value?.level,
      displayValue: lastCert.value?.level
        ? certificationTypes[lastCert.value.type].levels[lastCert.value.level]
        : undefined,
    },
  ]
}

export const Table = ({ properties, filter }) => {
  const propMeta = Object.values(properties)[0]
  const columns = [
    {
      id: 'propertyId',
      label: propMeta.propertyId.label,
    },
    {
      id: 'tempArea',
      label: propMeta.tempArea.label,
    },
    { id: 'certificationType', label: 'Typ av certifiering' },
    { id: 'certificationLevel', label: 'Nivå' },
  ]

  const rows = Object.entries(properties).map(([id, item]) => ({
    id,
    url: `/property/${id}`,
    label: item.propertyId.displayValue,
    cellValues: getCellValues(item),
  }))

  return (
    <SimpleTable
      columns={columns}
      rows={rows}
      filter={filter}
      sort={[
        { column: 'certificationType', order: 'asc' },
        { column: 'certificationLevel', order: 'asc' },
      ]}
    />
  )
}

/////////////////////////////////////////////////////////////////

const BarChart = styled(UnstyledBarChart)`
  max-width: 70%;
  max-height: 70vh;
`

export const Chart = ({ properties, onClick }) => {
  const [chartParams, setChartParams] = useState({ data: {} })
  useEffect(() => {
    setChartParams({
      title,
      ...getChartParams(properties, onClick),
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties])

  return <BarChart {...chartParams} />
}

/////////////////////////////////////////////////////////////////

const getChartParams = (properties, onClick) => {
  const levels = [
    { type: 'none', label: 'Ocertifierad' },
    ...Object.entries(certificationTypes)
      .map(([id, { label, levels }]) =>
        levels
          ? Object.entries(levels).map(([levelId, levelLabel]) => ({
              type: id,
              level: levelId,
              label: `${label} ${levelLabel}`,
            }))
          : { type: id, label }
      )
      .flat(),
  ]

  const grouped = groupBy(
    Object.entries(properties).map(([id, prop]) => ({
      _id: id,
      type: 'none',
      aTemp: prop.tempArea.value ?? 1000,
      ...getLastCertification(prop)?.value,
    })),
    ({ type }) => type
  )

  const typeKeys = Object.keys(grouped).sort((a, b) => a === 'none') // None last
  const labels = typeKeys.map(k =>
    k === 'none' ? 'Ocertifierad' : certificationTypes[k].label
  )

  const arrayWithElementAt = (el, idx, defaultValue, length) => {
    const arr = Array(length ?? idx + 1).fill(defaultValue)
    arr[idx] = el
    return arr
  }

  const lineData = {
    label: 'Yta [m2]',
    type: 'line',
    yAxisID: 'yArea',
    _labelIds: typeKeys,
    data: typeKeys.map(type =>
      grouped[type].map(({ aTemp }) => aTemp).reduce(addReducer)
    ),
  }

  const barData = levels
    .map(({ type, level, label }) => ({
      label,
      yAxisID: 'yNumber',
      stack: 0,
      _id: level,
      _labelIds: typeKeys,
      data: arrayWithElementAt(
        grouped[type ?? 'none']?.filter(
          ({ level: l }) => level === undefined || level === l
        )?.length ?? 0,
        typeKeys.indexOf(type),
        0,
        typeKeys.length
      ),
    }))
    .filter(({ data }) => Math.max(...data) > 0)

  const datasets = [lineData, ...barData]

  const chartOptions = {
    legend: false,
    scales: {
      yNumber: {
        beginAtZero: true,
        suggestedMax: 4,
        position: 'left',
        stacked: true,
        ticks: {
          callback: value => (value % 1 === 0 ? value : undefined),
        },
      },
      yArea: {
        beginAtZero: true,
        suggestedMax: 1000,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  return {
    data: { datasets, labels },
    options: chartOptions,
    onClick: item =>
      onClick({
        ...(item.labelId
          ? {
              certificationType:
                item.labelId === 'none' ? undefined : item.labelId,
            }
          : {}),
        ...(item.datasetId ? { certificationLevel: item.datasetId } : {}),
      }),
  }
}
