const config = {
  firebase: {
    apiKey: 'AIzaSyCZgLPZmy5lSvQfHoW8rUpcq3RpFBRO2us',
    authDomain: 'sciigo-29d85.firebaseapp.com ',
    projectId: 'sciigo-29d85',
    storageBucket: 'sciigo-29d85.appspot.com',
    documentBucket: 'sciigo-documents',
    messagingSenderId: '508074339344',
    appId: '1:508074339344:web:d3a84bc30d63b527936e95',
  },
  functions: {
    origin: 'https://europe-west1-sciigo-29d85.cloudfunctions.net',
    region: 'europe-west1',
  },
  streamValidation: true,
  debug: false,
}

export default config
