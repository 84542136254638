import { useTeam } from 'providers/Session'
import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import FormPopup, { Errors, ButtonRow } from 'components/Popup/FormPopup'
import { getFirebase } from 'utils/firebase'
import { CodeError } from 'utils/error'

const NewCreditorButton = () => (
  <FormPopup
    icon="plus"
    label="Ny Kreditgivare"
    dialogWidth="20rem"
    buttonStyle="normal"
  >
    <NewCreditorForm />
  </FormPopup>
)

const NewCreditorForm = ({ close }) => {
  const { set, creditors } = useTeam()

  const addCreditor = async ({ orgNr, newUser, email }) => {
    if (newUser) {
      throw new CodeError('uniplemented', 'Not implemented yet')
    }

    const { db } = getFirebase()
    const team = await db
      .doc(`orgmap/${orgNr}`)
      .get()
      .then(doc => doc.data())
    if (!team) {
      throw new CodeError('not-found', 'Org is not in sciigo')
    }
    if (team.type === 'reporter') {
      throw new CodeError('invalid-argument', 'Org is a reporter in Sciigo')
    }

    if (creditors?.[team.teamId]) {
      throw new CodeError('already-exists', 'Creditor is already connected')
    }

    await set({
      creditors: {
        [team.teamId]: {
          name: team.name,
          orgNr,
          pending: true,
        },
      },
    })
  }

  return (
    <Formik
      initialValues={{ orgNr: '123456-7890' }}
      onSubmit={async ({ orgNr }, { setSubmitting, setErrors, setStatus }) => {
        try {
          await addCreditor({ orgNr })
          setStatus({ done: true })
          setTimeout(close, 1000)
        } catch (e) {
          if (e.code === 'not-found') {
            setErrors({
              err: `Kreditgivare med organisationsnummer '${orgNr}' hittades inte`,
            })
          } else {
            console.log(e)
            setErrors({ err: 'Något gick fel' })
          }
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, values, status, errors }) => (
        <Form>
          <h2>Ny kreditgivare</h2>
          <FormField
            name="orgNr"
            label="Organisationsnummer"
            autoFocus={true}
          />
          <Errors errors={errors} />
          <ButtonRow
            disabled={isSubmitting || !values.orgNr}
            loading={isSubmitting}
            title={'Skicka förfrågan'}
            done={status?.done}
            successMessage="Förfrågan skickades"
          />
        </Form>
      )}
    </Formik>
  )
}

export default NewCreditorButton
