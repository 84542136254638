import { useContext, useState, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { BarChart as UnstyledBarChart } from 'components/Chart'
import { shade, transparentize } from 'polished'
import { histogram } from 'utils/data'
import SimpleTable from 'components/SimpleTable'

export const title = 'Antal Fastigheter per Energiklass'
export const Table = ({ properties, filter }) => {
  const meta = Object.values(properties)[0]
  const columns = ['propertyId', 'energyClass', 'tempArea'].map(id => ({
    id,
    ...meta[id],
  }))

  const rows = Object.entries(properties).map(([id, item]) => ({
    id,
    url: `/property/${id}`,
    label: item.propertyId.displayValue,
    cellValues: columns.slice(1).map(({ id }) => item[id]),
  }))

  return (
    <SimpleTable
      columns={columns}
      rows={rows}
      filter={filter}
      sort={[{ column: 'energyClass', order: 'asc' }]}
    />
  )
}

const BarChart = styled(UnstyledBarChart)`
  max-width: 70%;
  max-height: 70vh;
`

export const Chart = ({ properties, onClick }) => {
  const _theme = useContext(ThemeContext)
  const [chartParams, setChartParams] = useState({ data: {} })
  useEffect(() => {
    setChartParams({
      title,
      ...getChartParams(properties, onClick, _theme),
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties])

  return <BarChart {...chartParams} />
}

const getChartParams = (properties, onClick, _theme) => {
  const labels = 'ABCDEFG?'.split('')

  const numberOfProperties = histogram(
    properties,
    labels,
    item => item.energyClass?.value ?? '?'
  )

  const totalFloorArea = histogram(properties, labels, item => ({
    bucket: item.energyClass.value,
    value: item.tempArea.value ?? 1000,
  }))

  const backgroundColor = labels.map(cls =>
    cls !== '?' ? _theme.colors.energyClass[cls] : _theme.colors.light
  )

  const datasets = [
    {
      label: 'Yta [m2]',
      data: totalFloorArea,
      type: 'line',
      yAxisID: 'yArea',
      borderColor: transparentize(0.5, _theme.colors.primary),
    },
    {
      label: 'Antal fastigheter',
      data: numberOfProperties,
      backgroundColor,
      borderColor: backgroundColor.map(c => shade(0.15, c)),
      borderWidth: 2,
      yAxisID: 'yNumber',
    },
  ]

  const chartOptions = {
    legend: false,
    scales: {
      yNumber: {
        beginAtZero: true,
        suggestedMax: 4,
        position: 'left',
        ticks: {
          callback: value => (value % 1 === 0 ? value : undefined),
        },
      },
      yArea: {
        beginAtZero: true,
        suggestedMax: 1000,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }

  return {
    data: { datasets, labels },
    options: chartOptions,
    onClick: item =>
      onClick?.(
        item.label
          ? { energyClass: item.label !== '?' ? item.label : undefined }
          : {}
      ),
  }
}
