import { pick, isArray, isFunction, mapValues, defaultsDeep } from 'lodash'
import { isNumber, getValue } from 'utils/data'
import { enumerateString } from 'utils/string'
import { getScaleFactor } from 'utils/transform'
import { formatDate } from 'utils/date'

const sources = {
  roaring: {
    label: 'Roaring',
    description: timestamp =>
      `Sammanställd data från skatteverket, bolagsverket m. fl. Hämtad ${timestamp}`,
  },
  lmv_api: {
    label: 'Lantmäteriverket',
    description: timestamp => `Hämtad ${timestamp}`,
  },
  infotrader_api: {
    label: 'Infotrader',
    description: timestamp =>
      `Sammanställd data från Lantmäteriet, Skatteverket m. fl. Hämtad ${timestamp}`,
  },
  bv: {
    label: 'Boverket',
    description: timestamp => `Energideklaration utfärdad ${timestamp}`,
  },
  sgbc: {
    label: 'Swedish Green Building Council',
    description: timestamp => `Hämtad ${timestamp}`,
  },
}

const carriedMetaProps = [
  'label',
  'placeholder',
  'type',
  'multiple',
  'unit',
  'sourced',
  'labels',
  'transform',
  'description',
  'commented',
]

const addPropMeta = (prop, meta, env) => {
  if (meta === 'primitive') {
    return prop
  }
  const options = isFunction(meta.options) ? meta.options(env) : meta.options
  const visible = isFunction(meta.visible) ? meta.visible(env) : meta.visible
  const validate = isFunction(meta.validate)
    ? value => meta.validate(value, env)
    : undefined

  const value = getValue(prop)

  const displayValue = getDisplayValue({
    value,
    options,
    env,
    ...pick(meta, ['type', 'unit', 'format', 'labels', 'multiple']),
  })

  return defaultsDeep(
    {
      items:
        meta.type === 'list' ? getItems(prop?.items, meta, env) : undefined,
      value,
      displayValue,
      comment: prop?.comment,
      options,
      visible,
      validate,
      editable: meta.editable !== false,
      timestamp: prop?.timestamp,
      link: isFunction(meta.link) && value ? meta.link(value, env) : meta.link,
      section:
        meta.type === 'section' || meta.type === 'fieldArray'
          ? createSection(value, meta.fields)
          : undefined,
      fieldArray:
        meta.type === 'fieldArray'
          ? createFieldArray(value, meta.fields)
          : undefined,
      source:
        meta.sourced !== false
          ? getSource(
              prop?.sourcedData,
              prop?.value,
              prop?.document,
              env?.object?.documents
            )
          : undefined,
      displaySection:
        (meta.type === 'section' || meta.type === 'fieldArray') && !meta.format
          ? true
          : undefined,
    },
    // prop,
    pick(meta, carriedMetaProps)
  )
}

const getSource = (sourcedData, value, document, documents) => {
  if (value !== undefined) {
    return {
      type: 'manual',
      label: 'Manuell inmatning',
      document: getDocument(document, documents),
      reliability: document ? 'supported' : 'unsupported',
      originalSource: sourcedData?.value
        ? sources[sourcedData.type]
        : undefined,
    }
  }
  if (!sourcedData) {
    return {}
  }
  if (!sources[sourcedData.type]) {
    throw new Error(`Unknown source ${sourcedData.type}`)
  }
  const { label, description } = sources[sourcedData.type]
  const timestamp = sourcedData.timestamp

  return {
    type: sourcedData.type,
    label,
    timestamp,
    description: isFunction(description)
      ? description(timestamp.toLocaleDateString())
      : description,
    reliability: 'verified',
  }
}

const getDocument = (id, documents) => {
  if (documents?.items[id]) {
    const item = documents?.items[id]
    const value = getValue(item)
    const displayValue = value.name
    return {
      id,
      value,
      displayValue,
    }
  }
}

const defaultBooleanLabels = ['Ja', 'Nej']

const dvSelectOptions = (value, { options, multiple }) =>
  isArray(options)
    ? multiple && isArray(value)
      ? enumerateString(
          options.filter(o => value.includes(o.value)).map(o => o.label)
        )
      : options.find(o => o.value === value)?.label ?? value
    : undefined

const dvSectionFieldArray = value =>
  value && !(isArray(value) && value.length === 0) ? '(Flera värden)' : '(Tom)'

const displayValueGenerators = {
  file: value => (value?.hash ? '(Fil)' : undefined),
  section: dvSectionFieldArray,
  fieldArray: dvSectionFieldArray,
  options: dvSelectOptions,
  select: dvSelectOptions,
  boolean: (value, { labels }) =>
    labels ? labels[+!value] : defaultBooleanLabels[+!value],
}

const getDisplayValue = ({
  value,
  type,
  unit,
  format,
  options,
  labels,
  multiple,
  env,
}) => {
  const displayValue =
    value === undefined || value === '' || type === 'list'
      ? undefined
      : isFunction(format)
      ? format(value, env)
      : displayValueGenerators[type]
      ? displayValueGenerators[type](value, {
          options,
          labels,
          multiple,
          env,
        })
      : formatValue(value, type, unit)

  return displayValue ? `${displayValue}${unit ? ` ${unit}` : ''}` : undefined
}

const formatValue = (value, type, unit) => {
  if (type?.indexOf('number') === 0) {
    const decimals = type.split('.')[1] ?? 0
    const scaleFactor = getScaleFactor(unit)
    return isNumber(value)
      ? (parseFloat(value) * scaleFactor)
          .toLocaleString('en-US', {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          })
          .replaceAll(',', ' ')
      : value
  } else if (type?.indexOf('percentage') === 0) {
    const decimals = type.split('.')[1] ?? 0
    return formatValue(100 * value, `number.${decimals}`)
  } else if (type === 'date') {
    return value ? formatDate(value) : undefined
  } else {
    return value
  }
}

const getItems = (items, meta, env) =>
  items
    ? mapValues(items, (item, itemId) =>
        addPropMeta(
          item,
          {
            ...pick(meta, [
              'transform',
              'format',
              'options',
              'visible',
              'labels',
              'fields',
              'unit',
              'sourced',
              'link',
            ]),
            type: meta.itemType,
          },
          { itemId, ...env }
        )
      )
    : undefined

const createSection = (data, meta, env) =>
  !meta
    ? data
    : mapValues(meta, (propMeta, key) =>
        addPropMeta({ value: data?.[key] ?? undefined }, propMeta, env)
      )

const createFieldArray = (data, meta, env) => {
  return !meta
    ? data
    : data?.length > 0
    ? data.map(value =>
        mapValues(meta, (propMeta, key) =>
          addPropMeta({ value: value?.[key] ?? undefined }, propMeta, env)
        )
      )
    : []
}

export default addPropMeta
