import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import {
  Admin,
  Analytics,
  Dashboard,
  CreditorDetails,
  CreditorList,
  Login,
  Messages,
  PropertyDetails,
  PropertyList,
  PlanList,
  PlanDetails,
  OrganizationDetails,
  Report,
  ReportList,
  ReportReview,
  ReportDiscussion,
  ReporterDetails,
  ReporterList,
} from './routes'

function App() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/analytics/:type" component={Analytics} />

      <PrivateRoute path="/property/list" component={PropertyList} />
      <PrivateRoute
        path="/report/:reportId/property/:propertyId"
        component={PropertyDetails}
      />
      <PrivateRoute path="/property/:propertyId" component={PropertyDetails} />

      <PrivateRoute path="/plan/list" component={PlanList} />
      <PrivateRoute
        path="/report/:reportId/plan/:planId"
        component={PlanDetails}
      />
      <PrivateRoute path="/plan/:planId" component={PlanDetails} />

      <PrivateRoute
        path="/report/:reportId/organization/:orgId"
        component={OrganizationDetails}
      />
      <PrivateRoute
        path="/organization/:orgId"
        component={OrganizationDetails}
      />

      <PrivateRoute path="/reporter/list" component={ReporterList} />
      <PrivateRoute path="/reporter/:id" component={ReporterDetails} />
      <PrivateRoute path="/creditor/list" component={CreditorList} />
      <PrivateRoute path="/creditor/:id" component={CreditorDetails} />

      <PrivateRoute path="/messages/:id?" component={Messages} />
      <PrivateRoute path="/report/list" component={ReportList} />
      <PrivateRoute
        path="/report/:id/discussion"
        component={ReportDiscussion}
      />
      <PrivateRoute path="/report/:id/review" component={ReportReview} />
      <PrivateRoute path="/report/:id" component={Report} />
      <PrivateRoute path="/admin" component={Admin} />
      <PrivateRoute path="/" component={Dashboard} />
    </Switch>
  )
}

export default App
