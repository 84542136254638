import { Fragment } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { Link } from 'react-router-dom'
import { max } from 'lodash'
import { getValue } from 'utils/data'
import { formatDate } from 'utils/date'
import Card from 'components/Card'
import Icon from 'components/Icon'
import { certificationLabel } from 'data/certificationTypes'
import EnergyClass from 'components/EnergyClass'
import Pie from './Pie'
import EnergyClassBarChart from './EnergyClassBarChart'
import CertificationBarChart from './CertificationBarChart'
import { getSummaryKpis, getPropertyTypeCategoryLabel } from './kpis'
import KpiTable from './KpiTable'
import Markdown, { InlineMarkdown } from 'components/Markdown'
// import Debug from 'components/Debug'

const Wrapper = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  align-items: start;
`

const Kpis = styled.div``

const OrgTitleLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: middle;
  svg {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    margin-right: 0.5rem;
  }
  h2 {
    margin: 0;
  }
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const DataCoverage = styled.div`
  min-width: 0;
`

const GraphWrapper = styled.div`
  min-width: 0;
  > div {
    height: 350px;
  }
`

const Review = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  display: grid;
  grid-template-columns: 15rem auto;
  row-gap: 1rem;
  h3 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
`
const ReviewCommentLabel = styled(InlineMarkdown)`
  * {
    font-weight: 500;
  }
`
const ReviewComment = styled(Markdown)`
  p:first-child {
    margin-top: 0;
  }
`

const ObjectList = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
`

const ObjectTable = styled.div`
  margin-top: 1rem;
  row-gap: 0.3rem;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 10rem auto 10rem;
  column-gap: 0;
  align-items: center;
  > a {
    display: flex;
    align-items: center;
    > svg {
      margin: 0;
      margin-right: 0.5rem;
    }
    p {
      ${ifProp('$header', 'font-weight: 500;', '')}
      margin: 0;
    }
  }
  > ${EnergyClass} {
    font-size: 1rem;
    line-height: 1.5;
    height: 1.5rem;
    width: 1.5rem;
  }
  > a > p {
    font-weight: ${ifProp('$focus', 500, 300)};
  }

  background-color: ${ifProp(
    '$focus',
    theme('colors.highlight'),
    'transparent'
  )};
`

const HeaderRow = styled(Row)`
  font-weight: 500;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid gray;
`

const getCertifications = ({ certifications }) =>
  !certifications.items
    ? '—'
    : Object.values(certifications.items)
        .map(cert => certificationLabel(getValue(cert), false))
        .join(', ')

const SummaryReport = ({
  report,
  snapshot: teamData,
  qualificationResults: result,
}) => {
  const orgId = Object.keys(teamData.organizations)[0]
  const kpis = getSummaryKpis(teamData.properties)

  const items = [
    ...Object.entries(teamData.properties).map(([id, prop]) => ({
      id,
      icon: 'building',
      title: prop.propertyId.displayValue,
      type: getPropertyTypeCategoryLabel(prop),
      energyClass: getValue(prop.energyClass),
      certification: getCertifications(prop),
      url: `/report/${report.id}/property/${id}`,
      dataCoverage: `${(result.properties[id].progress * 100).toFixed()} %`,
      focus: report.data?.property && id === report.data.property,
    })),
  ].sort(({ focus: f1, energyClass: e1 }, { focus: f2, energyClass: e2 }) =>
    f1 ? -1 : f2 ? 1 : `${e1}`.localeCompare(`${e2}`, 'sv')
  )

  const reportDate = max(Object.values(report.snapshots ?? {}))

  return (
    <Card title={`Rapport ${formatDate(reportDate)}`}>
      <Wrapper>
        <Kpis>
          <OrgTitleLink to={`/report/${report.id}/organization/${orgId}`}>
            <Icon type="organization" />
            <h2>{getValue(teamData.organizations[orgId].name)}</h2>
          </OrgTitleLink>
          <KpiTable items={Object.values(kpis)} />
        </Kpis>
        <DataCoverage>
          <Pie values={[result.progress, 0, 1 - result.progress]} />
        </DataCoverage>
        <GraphWrapper>
          <h3>Fastigheter per Energiklass</h3>
          <EnergyClassBarChart
            properties={teamData.properties}
            areaHeight={200}
          />
        </GraphWrapper>
        <GraphWrapper>
          <h3>Fastigheter per Certifieringsnivå</h3>
          <CertificationBarChart
            properties={teamData.properties}
            areaHeight={200}
          />
        </GraphWrapper>
        <Review>
          <h3>Rådgivarens Kommentarer</h3>
          {['reportComment', 'reporterComment', 'futurePlansComment'].map(
            key => (
              <Fragment key={key}>
                <ReviewCommentLabel>{report[key].label}</ReviewCommentLabel>
                <ReviewComment>
                  {report[key].displayValue ?? '(Inga kommentarer)'}
                </ReviewComment>
              </Fragment>
            )
          )}
        </Review>
        <ObjectList>
          <h3>Fastigheter</h3>
          <ObjectTable>
            <HeaderRow>
              <div>Fastighet</div>
              <div>Typ</div>
              <div>Energiklass</div>
              <div>Certifiering</div>
              <div>Datatäckning</div>
            </HeaderRow>
            {items.map(
              ({
                id,
                url,
                title,
                type,
                energyClass,
                certification,
                dataCoverage,
                focus,
              }) => (
                <Row key={id} $focus={focus}>
                  <Link to={url}>
                    <Icon type="building" key="icon" />
                    <p key="p">{title}</p>
                  </Link>
                  <p>{type}</p>
                  <EnergyClass value={energyClass} />
                  <p>{certification}</p>
                  <p>{dataCoverage}</p>
                </Row>
              )
            )}
          </ObjectTable>
        </ObjectList>
      </Wrapper>
      {/*<Debug value={items}/>*/}
    </Card>
  )
}

export default SummaryReport
