import { histogram, getValue } from 'utils/data'
import { Document, Page } from 'components/Pdf/elements'
import {
  getSummaryKpis,
  getPropertyKpis,
  getPropertyTypeCategoryLabel,
} from '../kpis'
import { certificationLabel } from 'data/certificationTypes'

import getCertificationResults from './getCertificationResults'
import Summary from './Summary'
import Organization from './Organization'
import Property from './Property'

const summarizeProperty = prop => ({
  id: prop.id,
  title: prop.propertyId.displayValue,
  type: getPropertyTypeCategoryLabel(prop),
  details: Object.values(getPropertyKpis(prop)),
  comment: prop.comment,
  energyClass: getValue(prop.energyClass),
  certifications: prop.certifications.items
    ? Object.values(prop.certifications.items)
        .map(cert => certificationLabel(getValue(cert), false))
        .join(', ')
    : undefined,
  focus: prop.focus,
  progress: prop.result.progress,
})

const TicketReport = ({ snapshot, report, qualificationResults }) => {
  const properties = Object.entries(snapshot.properties)
    .map(([id, prop]) => ({
      id,
      ...prop,
      result: qualificationResults.properties[id],
      focus: report.data?.property && id === report.data.property,
      comment: report.propertySpecificComments.items?.[id]?.displayValue,
    }))
    .map(summarizeProperty)
    .sort(({ focus: f1, energyClass: e1 }, { focus: f2, energyClass: e2 }) =>
      f1 ? -1 : f2 ? 1 : `${e1}`.localeCompare(`${e2}`, 'sv')
    )

  const organization = {
    name: getValue(Object.values(snapshot.organizations)[0].name),
    details: Object.values(getSummaryKpis(snapshot.properties)),
    comments: ['reportComment', 'reporterComment', 'futurePlansComment'].map(
      key => ({ label: report[key].label, value: report[key].displayValue })
    ),
    properties,
    results: {
      progress: qualificationResults.progress,
      energyClass: {
        properties: histogram(snapshot.properties, 'ABCDEFG?'.split(''), item =>
          getValue(item.energyClass ?? '?')
        ),
        totalArea: histogram(
          snapshot.properties,
          'ABCDEFG?'.split(''),
          item => ({
            bucket: getValue(item.energyClass) ?? '?',
            value: getValue(item.tempArea) ?? 0,
          })
        ),
      },
      certifications: getCertificationResults(snapshot.properties),
    },
  }

  return (
    <Document>
      <Page>
        <Summary
          title={report.title.displayValue}
          reporter={report.reporter.displayValue}
          status={report.status}
          progress={qualificationResults.progress}
        />
        <Organization {...organization} />
      </Page>
      {properties.map(property => (
        <Page key={property.id}>
          <Property {...property} />
        </Page>
      ))}
    </Document>
  )
}

export default TicketReport
