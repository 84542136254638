import { backendCall } from 'utils/firebase'

const humlegarden = '556682-1202'
const bankx = '123456-7890'
// const swedbank = '502017-7753'
const dalslandssparbank = '562500-5243'
const dalslandsgruppen = '556919-0381'

const extractEpcs = {
  title: 'Extract EPC data with energy class (long run time)',
  form: {},
  submit: async () => backendCall('extractEpcs', { withEnergyClass: true }),
}

const extractOldEpcs = {
  title: 'Extract EPC data without energy class (pretty long run time)',
  form: {},
  submit: async () => backendCall('extractEpcs', { withEnergyClass: false }),
}

const extractCerts = {
  title: 'Extract certification data',
  form: {},
  submit: async () => backendCall('extractCertifications'),
}

const addReporterToCreditor = {
  title: 'Add reporter to creditor',
  form: {
    creditor: { label: 'Orgnr (creditor)' },
    reporter: { label: 'Orgnr (reporter)' },
  },
  initialValues: {
    reporter: dalslandsgruppen,
    creditor: dalslandssparbank,
  },
  submit: ({ reporter, creditor }) =>
    backendCall('addReporterToCreditorAdmin', {
      reporter,
      creditor,
    }),
}

const addPropertyToTeam = {
  title: 'Add property to team',
  form: {
    OrgNr: {},
    PropertyId: {},
  },
  initialValues: {
    OrgNr: dalslandsgruppen,
    PropertyId: 'Dals-Ed Kårslätt 1:34',
  },
  submit: ({ OrgNr, PropertyId }) =>
    backendCall('addPropertyToTeam', { orgNr: OrgNr, propertyId: PropertyId }),
}

const addReporterTeam = {
  title: 'Add new Team (reporter)',
  form: {
    OrgNr: {},
    Email: { type: 'email' },
  },
  initialValues: {
    OrgNr: dalslandsgruppen,
    Email: 'martin@sciigo.com',
  },
  submit: async ({ OrgNr, Email }) =>
    backendCall('addReporter', {
      orgNr: OrgNr,
      email: Email,
    }),
}

const addCreditorTeam = {
  title: 'Add new team (creditor)',
  form: {
    OrgNr: {},
    Name: {},
    Email: { type: 'email' },
  },
  initialValues: {
    OrgNr: dalslandssparbank,
    Name: 'Dalslands Sparbank',
    Email: 'martin@sciigo.com',
  },
  submit: ({ Name, Email, OrgNr }) =>
    backendCall('addCreditor', { name: Name, email: Email, orgNr: OrgNr }),
}

const addUsersToTeam = {
  title: 'Add users to team',
  form: {
    Emails: {
      type: 'textArea',
    },
    OrgNr: {},
  },
  initialValues: {
    Emails: 'demo+1@sciigo.com\ndemo+2@sciigo.com\ndemo+3@sciigo.com',
    OrgNr: humlegarden,
  },
  submit: async ({ Emails, OrgNr }) =>
    backendCall('addUsersToTeam', { emails: Emails.split('\n'), orgNr: OrgNr }),
}

const setRequirementMatricesForCreditor = {
  title: 'Set requrement matrices for creditor',
  form: {
    OrgNr: {},
    matrixIds: {
      label: 'Requirement matrix ids (one per row)',
      type: 'textArea',
    },
  },
  initialValues: {
    OrgNr: bankx,
    matrixIds: 'swedbank',
  },
  submit: async ({ OrgNr, matrixIds }) =>
    backendCall('setRequirementMatrices', {
      orgNr: OrgNr,
      matrixIds: matrixIds.split('\n'),
    }),
}

const rebuildOrgMap = {
  title: 'Rebuild org map',
  form: {},
  submit: () => backendCall('rebuildOrgMap'),
}

const test = {
  title: 'Test',
  form: {},
  submit: () => backendCall('test'),
}

const actions = {
  test,
  setRequirementMatricesForCreditor,
  addPropertyToTeam,
  addReporterToCreditor,
  addReporterTeam,
  addCreditorTeam,
  addUsersToTeam,
  extractCerts,
  extractEpcs,
  extractOldEpcs,
  rebuildOrgMap,
}

export default actions
