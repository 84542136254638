import Button from 'components/Button'
import BaseSection, {
  StyledList,
  ItemTitle,
  ListFooter,
  Empty,
} from 'components/Section/BaseSection'

// TODO: Delete plan
const PlansSection = ({ title, plans, add, editable }) => {
  const items = Object.entries(plans.items ?? {}).map(([id, item]) => ({
    id,
    ...item,
  }))

  return (
    <BaseSection title={title}>
      {items.length === 0 && !(plans.editable && editable) && (
        <Empty>(Inga planer)</Empty>
      )}
      <StyledList data={items}>
        <ItemTitle />
      </StyledList>
      {plans.editable && editable && (
        <ListFooter>
          <Button onClick={add}>Skapa plan</Button>
        </ListFooter>
      )}
    </BaseSection>
  )
}

export default PlansSection
