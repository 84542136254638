import { useEffect, useState } from 'react'
import { getBounds } from 'utils/data'
import { BubbleChart } from 'components/Chart'
import SimpleTable from 'components/SimpleTable'

export const title = 'Ursprungligt Primärenergital och förväntad förändring'
export const Table = ({ plans, properties, filter }) => {
  const columns = [
    { id: 'title', _label: 'Plan' },
    { id: 'propertyId', label: 'Fastighet' },
    {
      id: 'currentPed',
      label: 'E_{PED}',
      unit: 'kWh/m2',
      type: 'number.0',
    },
    {
      id: 'deltaPed',
      label: 'Sänkning E_{PED}',
      unit: '%',
      type: 'percentage',
    },
  ]
  const rows = Object.entries(plans ?? {}).map(([id, plan]) => {
    const property = properties[plan.property.value]
    const ped0 = property.primaryEnergyDemand.value ?? 0
    const ped1 = plan.primaryEnergyDemandTarget.value ?? 0
    const deltaPed = ped1 ? 1 - ped1 / ped0 : undefined

    return {
      id,
      url: `/plan/${id}`,
      label: plan.title.displayValue,
      cellValues: [
        property.propertyId,
        property.primaryEnergyDemand,
        {
          value: deltaPed,
          displayValue: (deltaPed * 100).toFixed(),
          type: 'percentage',
          unit: '%',
        },
      ],
    }
  })
  return (
    <SimpleTable
      columns={columns}
      rows={rows}
      filter={filter}
      sort={[
        { column: 'deltaPed', order: 'desc' },
        { column: 'currentPed', order: 'desc' },
      ]}
    />
  )
}

/////////////////////////////////////////////////////////////////

export const Chart = ({ plans, properties, onClick }) => {
  const [chartParams, setChartParams] = useState({ data: {} })
  useEffect(() => {
    setChartParams({
      title,
      ...getChartParams(plans ?? {}, properties, onClick),
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, plans])

  return <BubbleChart {...chartParams} />
}

/////////////////////////////////////////////////////////////////

const getChartParams = (plans, properties, onClick) => {
  const bubbleData = Object.entries(plans).map(([id, plan], i) => {
    const propertyId = plan.property.value
    const property = properties[propertyId]
    const ped0 = property.primaryEnergyDemand.value
    const ped1 = plan.primaryEnergyDemandTarget.value ?? ped0 * 0.7
    const deltaPed = 1 - ped1 / ped0
    const budget = plan.totalInvestment.value ?? 15 * Math.random()
    console.log(budget)
    return {
      x: ped0,
      y: deltaPed * 100,
      r: budget,
      c: 1,
      title: plan.title.displayValue,
      subtitle: property.propertyId.displayValue,
      id,
    }
  })

  const bounds = getBounds(bubbleData, ['x', 'y', 'r'])
  const scale = (0.1 * Math.max(bounds.xr, bounds.yr)) / bounds.rr
  const scaledBubbleData = bubbleData.map(datum => ({
    ...datum,
    r: datum.r * scale,
  }))

  const desiredPed = 55

  const datasets = [
    {
      label: 'Planer',
      type: 'bubble',
      clip: 0,
      data: scaledBubbleData,
    },
    {
      label: 'Gränsnivå',
      type: 'scatter',
      showLine: true,
      pointRadius: 0,
      hoverRadius: 0,
      tooltipHidden: true,
      data: Array(100)
        .fill()
        .map((a, i) => ({
          x: i + desiredPed,
          y: 100 * (1 - desiredPed / (i + desiredPed)),
        })),
    },
  ]

  const chartOptions = {
    plugins: {
      tooltip: {
        custom: tooltipModel => {
          if (!tooltipModel.body || tooltipModel.body.length < 1) {
            tooltipModel.caretSize = 0
            tooltipModel.xPadding = 0
            tooltipModel.yPadding = 0
            tooltipModel.cornerRadius = 0
            tooltipModel.width = 0
            tooltipModel.height = 0
          }
        },
        filter: (item, idx, _, data) =>
          !data.datasets[item.datasetIndex].tooltipHidden,
      },
    },
    scales: {
      xAxis: {
        title: {
          display: true,
          text: 'Ursprungligt Primärenergital [kWh/m²]',
        },
      },
      yAxis: {
        title: {
          text: 'Sänkning Primärenergital [%]',
          display: true,
        },
        suggestedMin: 15,
        suggestedMax: 45,
      },
    },
  }

  return {
    data: { datasets },
    options: chartOptions,
    onClick: item => {
      onClick(item.value?.id ? { id: item.value.id } : {})
    },
  }
}
