import { useState, useEffect } from 'react'
import { getFirebase } from 'utils/firebase'

const useBatchFunction = () => {
  const [state, setState] = useState({})
  const { batchFunctionId } = state

  useEffect(() => {
    const { db } = getFirebase()
    const unsubscribe = batchFunctionId
      ? db.doc(`functions/${batchFunctionId}`).onSnapshot(doc => {
          const data = doc.data()
          if (data.error) {
            setState(data)
          } else if (data.done) {
            setState(data)
          } else {
            setState({ batchFunctionId, ...data })
          }
        })
      : null

    return () => {
      unsubscribe?.()
    }
  }, [batchFunctionId])

  const run = async (path, data, args) => {
    const { functions } = getFirebase()
    if (batchFunctionId) {
      throw new Error(
        `Batch function already running, no parallell calls (yet)`
      )
    }
    const fn = functions.httpsCallable(path, args)
    const result = await fn(data)
    if (!result?.data?.fnid) {
      throw new Error(
        `Result from callable function ${path} is not a function id: ${JSON.stringify(
          result
        )}`
      )
    }
    setState({ batchFunctionId: result.data.fnid })
  }
  return { run, ...state }
}

export default useBatchFunction
