import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { download } from 'components/Pdf'
import TicketReport from './TicketReportPdf'
import Switch from 'components/Switch'
import Loader from 'components/Loader'
import Debug from 'components/Debug'
import Button from 'components/Button'
import { getValue, getLatestSnapshotId, isReportOpen } from 'utils/data'
import { useSnapshot } from 'providers/Session'
import GeneralStatus from './GeneralStatus'
import SummaryReport from './SummaryReport'
import PropertyReport from './PropertyReport'
import UnstyledCloseReportButton from './CloseReportButton'
import { qualify } from 'qualifier'
import ActionWrapper from 'components/Section/ActionWrapper'

const StyledButton = styled(Button)`
  margin-left: 1rem;
`

const ExportButton = ({ onClick, icon, ...props }) => {
  const [loading, setLoading] = useState(false)
  return (
    <StyledButton
      disabled={loading}
      loading={loading}
      icon={icon}
      onClick={async () => {
        setLoading(true)
        try {
          await new Promise(resolve => setTimeout(resolve, 0))
          await onClick()
        } catch (e) {
          console.error(e)
        } finally {
          setLoading(false)
        }
      }}
      {...props}
    />
  )
}

const CloseReportButton = styled(UnstyledCloseReportButton)``

const CreditorView = ({ teamData, report }) => {
  const snapshotId = getLatestSnapshotId(report)

  const { data: snapshot, loading } = useSnapshot(
    getValue(report.reporter),
    snapshotId,
    report.id
  )

  console.log(snapshotId, loading, snapshot)

  if (snapshot) {
    snapshot.property = Object.values(snapshot.properties)[0]
    console.log('Got snapshot')
  }

  const result = useMemo(() => {
    if (snapshot) {
      const { properties, organizations } = snapshot
      const requirementMatrices = teamData.teamData.requirements
      const result = qualify({ properties, organizations }, requirementMatrices)
      return result
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot])

  const requested = getValue(report.status) === 'requested'

  const propertyList = Object.entries(snapshot?.properties ?? {})
    .map(([id, property]) => ({
      id,
      focus: id === report.data?.property,
      ...property,
    }))
    .sort((p1, p2) =>
      p1.focus
        ? -1
        : p2.focus
        ? 1
        : p1.propertyId.displayValue.localeCompare(
            p2.propertyId.displayValue,
            'sv'
          )
    )

  return loading ? (
    <Loader />
  ) : (
    <>
      {!requested && (
        <Switch
          labels={['Data', 'Kommentarer', 'Diskussion']}
          urls={[
            `/report/${report.id}`,
            `/report/${report.id}/review`,
            `/report/${report.id}/discussion`,
          ]}
          activeIndex={0}
        />
      )}
      <ActionWrapper compact={!requested}>
        <CloseReportButton
          disabled={!isReportOpen(report)}
          report={report}
          teamId={teamData.id}
        />
        {result && (
          <ExportButton
            icon={'pdf'}
            title="Ladda ner rapport"
            onClick={() =>
              download(
                <TicketReport
                  propertyList={propertyList}
                  snapshot={snapshot}
                  report={report}
                  qualificationResults={result}
                />,
                report.title.displayValue
              )
            }
          />
        )}
      </ActionWrapper>
      <GeneralStatus report={report} qualificationResults={result} />
      {loading ? (
        <Loader />
      ) : requested || !snapshot ? null : (
        <>
          <SummaryReport
            snapshot={snapshot}
            report={report}
            qualificationResults={result}
          />
          {propertyList.map(({ id, ...property }) => (
            <PropertyReport
              key={id}
              property={{ id, ...property }}
              report={report}
              qualificationResults={result}
            />
          ))}
        </>
      )}
      <Debug value={report} />
    </>
  )
}

export default CreditorView
