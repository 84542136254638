import { useContext } from 'react'
import { AuthContext, AuthProvider } from './Auth'
import { UserContext, UserProvider } from './User'
import { TeamContext, TeamProvider } from './Team'
export { useProperty, useProperties } from './Property'
export { usePlan, usePlans } from './Plan'
export {
  useReport,
  useReports,
  addReport,
  requestReport,
  closeReport,
} from './Report'
export { default as useSnapshot } from './Snapshot'
export { AuthProvider, UserProvider, TeamProvider }

export const useAuth = () => useContext(AuthContext)
export const useUser = () => useContext(UserContext)
export const useTeam = () => useContext(TeamContext)
