import { maxBy, groupBy } from 'lodash'
import { getValue } from 'utils/data'
import certificationTypes, {
  unifiedLevels,
  getUnifiedLevel,
} from 'data/certificationTypes'

const getLastCertification = prop =>
  maxBy(
    Object.values(prop.certifications.items ?? {}).map(item => {
      const { type, level, dateIssued } = getValue(item)
      return {
        type,
        level: getUnifiedLevel(type, level),
        dateIssued,
      }
    }),
    'dateIssued'
  )

const groupPropertiesByCertification = properties => {
  const grouped = groupBy(
    Object.entries(properties).map(([id, prop]) => ({
      _id: id,
      type: 'none',
      level: 'none',
      aTemp: getValue(prop.tempArea) ?? 0,
      ...getLastCertification(prop),
    })),
    ({ level }) => level
  )
  return grouped
}

const getCertificationResults = properties => {
  const grouped = groupPropertiesByCertification(properties)
  const totalArea = Object.keys(unifiedLevels).map(level =>
    (grouped[level] ?? [])
      .map(({ aTemp }) => aTemp ?? 0)
      .reduce((a, b) => a + b, 0)
  )

  const propertyCount = [...Object.keys(certificationTypes), 'none']
    .map(type => ({
      id: type,
      data: Object.keys(unifiedLevels).map(level => ({
        type: level,
        value: grouped[level]
          ? grouped[level].filter(entry => entry.type === type).length
          : 0,
      })),
    }))
    .filter(({ data }) => data.some(({ value }) => value))

  return {
    totalArea,
    properties: propertyCount,
  }
}

export default getCertificationResults
