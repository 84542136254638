import { createElement, useState } from 'react'
import { useTeam } from 'providers/Session'
import Loader from 'components/Loader'
import * as PropertiesByEnergyClass from './PropertiesByEnergyClass'
import * as PropertiesByCertification from './PropertiesByCertification'
import * as PedByValuation from './PedByValuation'
import * as DeltaPedByBudget from './DeltaPedByBudget'
import * as DeltaPedByPed from './DeltaPedByPed'

import Page from 'components/Page'

const Report = ({ match }) => {
  const { type } = match.params

  const [filter, setFilter] = useState({})

  const { plans, properties, loading, error } = useTeam()

  const reports = {
    byEpcClass: PropertiesByEnergyClass,
    economicPotential: PedByValuation,
    planEfficiency: DeltaPedByBudget,
    planQuality: DeltaPedByPed,
    certification: PropertiesByCertification,
  }

  if (error) {
    console.error(error)
  }

  const onClick = newFilter => {
    setFilter(newFilter)
  }

  return (
    <Page title={reports[type].title}>
      {reports[type] ? (
        loading ? (
          <Loader />
        ) : properties ? (
          <>
            {createElement(reports[type].Chart, {
              plans,
              properties,
              filter,
              onClick,
            })}
            {createElement(reports[type].Table, { plans, properties, filter })}
          </>
        ) : (
          <div></div>
        )
      ) : (
        <div>No report of type {type}</div>
      )}
    </Page>
  )
}

export default Report
