import styled from 'styled-components'
import { theme } from 'styled-tools'

const Section = styled.section`
  background: ${theme('colors.white')};
  border-radius: 12px;
  margin: 2rem 0;
  box-shadow: 0px 0px 8px ${theme('colors.shadow')};
`

const TitleGroup = styled.hgroup`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: left;
  background: ${theme('colors.primary')};
  padding: 0.4rem 1rem 0.7rem;
`

const Title = styled.h2`
  color: ${theme('colors.white')};
  margin: 1rem 1rem 0.5rem;
`

const Subtitle = styled.h3`
  color: ${theme('colors.light')};
  margin: -0.5rem 1rem 0.5rem;
  font-size: 1rem;
`

const Card = ({ title, subtitle, description, className, children }) => (
  <Section className={className}>
    {(title || subtitle) && (
      <TitleGroup>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleGroup>
    )}
    {children}
  </Section>
)

export default styled(Card)``
