import { useEffect, useState } from 'react'
import {
  getFirebase,
  cleanData,
  FieldValue,
  convertDates,
} from 'utils/firebase'
import { addMeta } from 'data/meta'
import { toPlural } from 'utils/data'

export const useTeamPropertyMulti = (query, teamData, type) => {
  const [state, setState] = useState({
    loading: true,
  })

  const { db } = getFirebase()
  useEffect(() => {
    if (query) {
      const unsubscribe = teamData?.id
        ? db
            .collection(`teams/${teamData.id}/${toPlural(type)}`)
            .where(...query)
            .onSnapshot(snapshot => {
              const data = Object.fromEntries(
                snapshot.docs.map(doc => {
                  const data = convertDates(doc.data())
                  return [
                    doc.id,
                    addMeta(data, type, {
                      team: teamData,
                      object: data,
                      id: doc.id,
                    }),
                  ]
                })
              )

              setState({
                loading: false,
                data,
              })
            })
        : undefined
      return () => {
        unsubscribe?.()
        setState({ loading: true })
      }
    } else {
      setState({ loading: false })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(query?.[2]), teamData.id])

  return state
}

export const useTeamProperty = (id, teamData, type) => {
  const [state, setState] = useState({
    loading: true,
  })

  const { db } = getFirebase()

  useEffect(() => {
    const unsubscribe =
      id && teamData.id
        ? db
            .doc(`teams/${teamData.id}/${toPlural(type)}/${id}`)
            .onSnapshot(doc => {
              if (!doc.exists) {
                console.log(`${type} does not exist`)
                setState({
                  loading: false,
                })
              } else {
                const propertyData = convertDates(doc.data())
                setState({
                  propertyData,
                  loading: false,
                  data: addMeta(propertyData, type, {
                    team: teamData,
                    object: propertyData,
                    id,
                  }),
                })
              }
            })
        : undefined

    return () => {
      unsubscribe?.()
      setState({ loading: true })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, teamData.id])

  useEffect(() => {
    if (teamData.id && state.propertyData) {
      setState({
        loading: false,
        data: addMeta(state.propertyData, type, {
          team: teamData,
          object: state.propertyData,
          id,
        }),
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(teamData)])

  const set = data =>
    db
      .doc(`teams/${teamData.id}/${toPlural(type)}/${id}`)
      .set(data, { merge: true })

  const saveProperty = (propertyId, { value, document, comment }) => {
    return set({
      [propertyId]: cleanData(
        {
          value,
          document: document || undefined,
          comment: comment || undefined,
        },
        true
      ),
    })
  }

  const saveListItem = (propertyId, itemId, { value, document, comment }) =>
    set({
      [propertyId]: {
        items: {
          [itemId]: value
            ? cleanData(
                {
                  value,
                  document: document || undefined,
                  comment: comment || undefined,
                },
                true
              )
            : FieldValue.delete(),
        },
      },
    })

  return {
    saveProperty,
    saveListItem,
    ...state,
  }
}
