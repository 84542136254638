import { mapKeys } from 'lodash'
import { nanoid } from 'utils/data'
import * as _dalsland from './dalsland'
import * as _dalslandInternal from './dalslandInternal'
import * as _swedbank from './swedbank'

const transform = ({ requirements, ...props }) => ({
  requirements: mapKeys(requirements, k => nanoid()),
  ...props,
})

export const dalsland = transform(_dalsland)
export const swedbank = transform(_swedbank)
export const dalslandInternal = transform(_dalslandInternal)
