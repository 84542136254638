import { useEffect, useState } from 'react'
import { getBounds } from 'utils/data'
import { BubbleChart } from 'components/Chart'
import SimpleTable from 'components/SimpleTable'

export const title = 'Investeringsbudget och Förändring i Primärenergital'
export const Table = ({ plans, properties, filter }) => {
  const meta = Object.values(plans ?? {})[0]
  const columns = meta
    ? [
        { id: 'title', label: 'Plan' },
        { id: 'propertyId', label: 'Fastighet' },
        {
          id: 'totalInvestment',
          label: meta.totalInvestment.label,
        },
        {
          id: 'deltaPed',
          label: 'Sänkning E_{PED}',
          unit: '%',
          type: 'percentage',
        },
      ]
    : []
  const rows = Object.entries(plans ?? {}).map(([id, plan]) => {
    const property = properties[plan.property.value]
    const ped0 = property.primaryEnergyDemand.value ?? 0
    const ped1 = plan.primaryEnergyDemandTarget.value ?? 0
    const deltaPed = ped1 ? 1 - ped1 / ped0 : undefined
    return {
      id,
      url: `/plan/${id}`,
      label: plan.title.displayValue,
      cellValues: [
        property.propertyId,
        plan.totalInvestment,
        {
          value: deltaPed,
          displayValue: (deltaPed * 100).toFixed(),
          type: 'percentage',
          unit: '%',
        },
      ],
    }
  })

  return plans && Object.keys(plans).length > 0 ? (
    <SimpleTable
      columns={columns}
      rows={rows}
      filter={filter}
      sort={[
        { column: 'totalInvestment', order: 'desc' },
        // { column: 'deltaPed', order: 'desc' },
      ]}
    />
  ) : (
    <div>
      <h2>Det finns inga planer i portföljen</h2>
    </div>
  )
}

/////////////////////////////////////////////////////////////////

export const Chart = ({ plans, properties, onClick }) => {
  const [chartParams, setChartParams] = useState({ data: {} })
  useEffect(() => {
    setChartParams({
      title,
      ...getChartParams(plans ?? {}, properties, onClick),
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, plans])

  return Object.keys(plans ?? {}).length > 0 ? (
    <BubbleChart {...chartParams} />
  ) : (
    <div></div>
  )
}

/////////////////////////////////////////////////////////////////

const getChartParams = (plans, properties, onClick) => {
  const bubbleData = Object.entries(plans).map(([id, plan], i) => {
    const propertyId = plan.property.value
    const property = properties[propertyId]
    const ped0 = property.primaryEnergyDemand.value
    const ped1 = plan.primaryEnergyDemandTarget.value ?? ped0 * 0.7
    const deltaPed = 1 - ped1 / ped0
    const budget = plan.totalInvestment.value ?? 15 * Math.random()
    const aTemp = property.tempArea.value ?? 1000

    return {
      x: deltaPed * 100,
      y: budget,
      r: aTemp,
      c: 1,
      title: plan.title.displayValue,
      subtitle: property.propertyId.displayValue,
      id,
    }
  })

  const bounds = getBounds(bubbleData, ['x', 'y', 'r'])
  const scale = (0.1 * Math.max(bounds.xr, bounds.yr)) / bounds.rr
  const scaledBubbleData = bubbleData.map(datum => ({
    ...datum,
    r: datum.r * scale,
  }))

  const datasets = [
    {
      label: 'Planer',
      clip: 0,
      data: scaledBubbleData,
    },
  ]

  const chartOptions = {
    scales: {
      xAxis: {
        title: {
          display: true,
          text: 'Sänkning Primärenergital [%]',
        },
        suggestedMin: 15,
        suggestedMax: 45,
      },
      yAxis: {
        title: {
          text: 'Investering [MSEK]',
          display: true,
        },
      },
    },
  }

  const plugin = {
    beforeDraw: (chart, easing) => {
      const chartArea = chart.chartArea
      const ctx = chart.ctx
      const x30 = chart.scales.xAxis
        ? chart.scales.xAxis.getPixelForValue(30)
        : 0

      ctx.fillStyle = '#cfc'
      ctx.fillRect(
        x30,
        chartArea.top,
        chartArea.right - x30,
        chartArea.bottom - chartArea.top
      )
    },
  }
  return {
    data: { datasets },
    options: chartOptions,
    plugins: [plugin],
    onClick: item => {
      onClick(item.value?.id ? { id: item.value.id } : {})
    },
  }
}
