import { pick, mapValues, isFunction } from 'lodash'
import { getValue } from 'utils/data'

export class MissingDataError extends Error {
  constructor(data) {
    super('Missing Data')
    this.data = data
  }
}

export const requireProps = props => {
  Object.entries(props).forEach(([id, prop]) => {
    if (Array.isArray(prop)) {
      const missing = prop
        .filter(({ value }) => value === undefined)
        .map(({ label }) => ({
          label: 'Data saknas',
          value: label,
        }))
      if (missing.length > 0) {
        throw new MissingDataError(missing)
      }
    } else if (prop.type === 'list') {
      if (!prop.items || Object.keys(prop.items).length === 0) {
        throw new MissingDataError({
          [id]: {
            label: prop.label,
            error: `Inga ${prop.label.toLowerCase()}`,
          },
        })
      }
    } else if (prop.value === undefined) {
      throw new MissingDataError({
        [id]: {
          label: prop.label,
          error: 'Värde saknas',
        },
      })
    }
  })
}

const getDocumentItems = data =>
  data ? Object.assign({}, ...Object.values(data).map(getAllDocumentItems)) : {}

const getAllDocumentItems = obj =>
  obj
    ? obj.documents
      ? obj.documents.items
      : Object.assign(
          {},
          ...Object.values(obj).map(({ documents }) => documents?.items ?? {})
        )
    : {}

const carriedProps = ['label', 'value', 'displayValue', 'unit', 'type']
const calculate = ({ getData, getRelevant, getFulfilled }, inData) => {
  try {
    const data = getData(inData)
    const relevant = isFunction(getRelevant) ? getRelevant(data) : true
    return {
      relevant,
      fulfilled: relevant ? getFulfilled(data) : undefined,
      data: relevant
        ? mapValues(data, attr =>
            getCleanDataItem(attr, getDocumentItems(inData))
          )
        : {},
      missing: false,
    }
  } catch (e) {
    if (e.data) {
      return {
        relevant: true,
        fulfilled: false,
        missing: true,
        data: e.data,
      }
    } else {
      throw e
    }
  }
}

const getCleanDataItem = (item, documentItems) => ({
  ...pick(item, carriedProps),
  ...(item.items
    ? {
        items: mapValues(item.items, item =>
          getCleanDataItem(item, documentItems)
        ),
      }
    : {}),
  source: getSource(item, documentItems),
})

const getSource = ({ source, items }, documents) =>
  source
    ? source.type !== 'manual'
      ? {
          // Verified source
          id: source.id,
          type: source.type,
          label: `${source.label}${
            source.description ? `: ${source.description}` : ''
          }`,
        }
      : source.document
      ? {
          // Supporting document
          id: source.document.id,
          label: `Bilaga: ${source.document.displayValue}`,
          type: 'document',
        }
      : !items
      ? {
          // Manual source
          id: 'none',
          label: 'Källa saknas',
        }
      : undefined // List, source is on items (gets in recursion)
    : undefined

const getIndexedItem = (item, sourceMap) => ({
  ...item,
  ...(item.source
    ? {
        source: {
          ...item.source,
          idx: sourceMap[item.source.id].idx,
        },
      }
    : {}),
  ...(item.items
    ? { items: mapValues(item.items, item => getIndexedItem(item, sourceMap)) }
    : {}),
})

export const getReportResult = (requirements, inData) => {
  const sourceMap = {}

  const result = requirements
    .map(({ label, description, getRelevant, getFulfilled, getData }) => {
      const { data, relevant, fulfilled, missing } = calculate(
        { getData, getRelevant, getFulfilled },
        inData
      )

      return {
        label,
        description,
        relevant,
        fulfilled,
        missing,
        data,
      }
    })
    .filter(({ relevant }) => relevant)

  result.forEach(({ data }) => {
    Object.values(data).forEach(({ source, items }) => {
      if (source && !sourceMap[source.id]) {
        sourceMap[source.id] = {
          ...source,
          idx: Object.keys(sourceMap).length,
        }
      }
      Object.values(items ?? {}).forEach(({ source }) => {
        if (source && !sourceMap[source.id]) {
          sourceMap[source.id] = {
            ...source,
            idx: Object.keys(sourceMap).length,
          }
        }
      })
    })
  })

  const indexedResult = result.map(reqResult => ({
    ...reqResult,
    data: mapValues(reqResult.data, item => getIndexedItem(item, sourceMap)),
  }))

  return { result: indexedResult, sourceMap }
}

// Shorthands

export const requireOne = props =>
  Object.values(props).some(v => getValue(v) !== undefined)
    ? undefined
    : { fulfilled: false, missing: Object.keys(props) }

export const requireAll = props => {
  const missing = Object.entries(props)
    .filter(([k, v]) => getValue(v) === undefined)
    .map(([k]) => k)
  return missing.length ? { fulfilled: false, missing } : undefined
}

export const fulfilledOne = requirements =>
  requirements.some(({ fulfilled }) => fulfilled)

export const fulfilledAll = requirements =>
  requirements.every(({ fulfilled }) => fulfilled)

export const requireValue = (itemType, propNames) => ({
  getData: data => pick(data[itemType], propNames),
  getFulfilled: data => {
    requireProps(pick(data, propNames))
    return true
  },
})
