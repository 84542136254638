import { theme } from 'theme'
import { H1, P, Span, Strong } from 'components/Pdf/elements'
import { Row, Column } from 'components/Pdf/elements/Layout'

const Summary = ({ title, reporter, status, progress }) => (
  <>
    <H1 style={{ margin: '0 0 0.5em', lineHeight: 1.3, textAlign: 'center' }}>
      {title}
    </H1>
    <Row style={{ marginTop: '1em', marginBottom: '1em' }}>
      <Column width={1 / 2}>
        <P>
          <Strong>Status: </Strong>
          <Span
            style={{ color: status.value === 'open' ? theme.colors.brand : '' }}
          >
            {status.displayValue}
          </Span>
        </P>
        <P>
          <Strong>Kund: </Strong>
          <Span>{reporter}</Span>
        </P>
      </Column>
      <Column width={1 / 2} style={{ textAlign: 'right' }}>
        <Span style={{ fontSize: '2em', fontWeight: 400 }}>
          {(progress * 100).toFixed()} %
        </Span>
        <Span>klart</Span>
      </Column>
    </Row>
  </>
)

export default Summary
