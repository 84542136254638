import { Text, View } from './elements'
import { StyleSheet } from '@react-pdf/renderer'
import { theme } from 'theme'

const rem = 14
const styles = StyleSheet.create({
  paragraph: {
    lineHeight: 1.4,
    marginBottom: '0.5em',
  },
  strong: {
    fontWeight: 500,
  },
  em: {
    fontStyle: 'italic',
  },
  sup: {
    fontSize: '0.7em',
    yOffset: 0.33,
  },
  sub: {
    fontSize: '0.7em',
    yOffset: -0.15,
  },
  header: {
    fontFamily: 'Josefin Sans',
    lineHeight: 1,
  },
  h1: {
    fontSize: '3em',
    fontWeight: 500,
    marginBottom: 0.0 * rem,
    color: theme.colors.primary,
  },
  h2: {
    fontSize: '1.8em',
    marginBottom: 0.9 * rem,
    fontWeight: 400,
  },
  h3: {
    fontSize: '1.2em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 0.03,
    marginBottom: 0.6 * rem,
  },
  h4: {},
  span: {},
})

export const H1 = ({ style, children, ...props }) => (
  <View style={[styles.header, styles.h1, style]} {...props}>
    <Text>{children}</Text>
  </View>
)
export const H2 = ({ style, children, ...props }) => (
  <View style={[styles.header, styles.h2, style]} {...props}>
    <Text>{children}</Text>
  </View>
)
export const H3 = ({ style, children, ...props }) => (
  <View style={[styles.header, styles.h3, style]} {...props}>
    <Text>{children}</Text>
  </View>
)
export const H4 = ({ style, children, ...props }) => (
  <View style={[styles.header, styles.h4, style]} {...props}>
    <Text>{children}</Text>
  </View>
)

export const P = ({ style, children, ...props }) => (
  <View style={[styles.paragraph, style]} {...props}>
    <Text>{children}</Text>
  </View>
)

export const Strong = ({ style, ...props }) => (
  <Text style={[styles.strong, style]} {...props} />
)

export const Em = ({ style, ...props }) => (
  <Text style={[styles.em, style]} {...props} />
)

export const Sup = ({ style, ...props }) => (
  <Text style={[styles.sup, style]} {...props} />
)

export const Sub = ({ style, ...props }) => (
  <Text style={[styles.sub, style]} {...props} />
)
