import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTeam, useProperty, usePlans } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import { qualify } from 'qualifier'
import { getValue } from 'utils/data'
import Page from 'components/Page'
import Loader from 'components/Loader'
import Switch from 'components/Switch'
import { NakedButton } from 'components/Button'
import { PropertyReport } from 'components/Report'
import ReportSettingsForm from './ReportSettingsForm'
import { download } from 'components/Pdf'
import PdfPropertyReport from 'components/Report/pdf/PropertyReport'

const PdfButton = styled(NakedButton)`
  position: absolute;
  right: 2rem;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const Route = ({ match }) => {
  const [creditor, setCreditor] = useState()
  const { id: propertyId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: propertyData, loading: loadingProperty } = useProperty(
    propertyId,
    teamData
  )

  const planIds = Object.keys(propertyData?.plans?.items ?? {})

  const { data: plans, loading: loadingPlans } = usePlans(planIds, teamData)
  const loading = loadingTeam || loadingProperty || loadingPlans

  const { organizations } = teamData

  useRecent('property', propertyId, propertyData?.propertyId.displayValue)

  const { creditors } = teamData

  useEffect(() => {
    if (creditors) {
      setCreditor(Object.keys(creditors)[0])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(creditors)])

  const qualifications =
    loading || !creditors
      ? undefined
      : qualify(
          {
            property: { id: propertyId, ...propertyData },
            plans,
            organization: organizations[getValue(propertyData.organization)],
          },
          creditors[creditor ?? Object.keys(creditors)[0]].requirements,
          {
            includeInternal: teamData.teamData.type === 'creditor',
          }
        )

  return (
    <Page title={propertyData?.propertyId?.displayValue}>
      {loading ? (
        <Loader />
      ) : propertyData ? (
        <>
          <PdfButton
            icon="pdf"
            title="Ladda ner rapport"
            onClick={async () => {
              download(
                <PdfPropertyReport
                  {...{
                    property: propertyData,
                    qualifications,
                    plans,
                    organizations,
                  }}
                />,
                `Rapport ${
                  propertyData?.propertyId?.displayValue ?? 'fastighet'
                }`
              )
            }}
          />
          <Switch
            labels={['Data', 'Rapport']}
            urls={[`/property/${propertyId}`, `/property/${propertyId}/report`]}
            activeIndex={1}
          />
          {teamData?.teamData.type === 'reporter' && (
            <ReportSettingsForm
              creditors={creditors}
              creditor={creditor}
              setCreditor={setCreditor}
              reportTitle={propertyData.propertyId.displayValue}
              reportData={{
                property: propertyId,
                organization: getValue(propertyData.organization),
                plans: planIds,
              }}
            />
          )}

          <PropertyReport
            property={propertyData}
            qualifications={qualifications}
            plans={plans}
            organizations={organizations}
          />
        </>
      ) : (
        <div></div>
      )}
    </Page>
  )
}

export default Route
