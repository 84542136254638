import { useMemo } from 'react'
import Switch from 'components/Switch'
import Loader from 'components/Loader'
import Debug from 'components/Debug'
import ActionWrapper from 'components/Section/ActionWrapper'
import SendButton from './SendButton'
import { isReportOpen, getValue } from 'utils/data'
import { useProperties } from 'providers/Session'
import { qualify } from 'qualifier'

import GeneralStatus from './GeneralStatus'
import DataStatus from './DataStatus'

const ReporterView = ({ teamData, report }) => {
  const { data: properties } = useProperties(teamData)

  const result = useMemo(() => {
    if (properties) {
      const { creditors } = teamData
      const requirementMatrices =
        creditors[getValue(report.creditor)].requirements
      const result = qualify(
        { properties, organizations: teamData.organizations },
        requirementMatrices
      )
      return result
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(properties)])

  return !result ? (
    <Loader />
  ) : (
    <>
      <Switch
        labels={['Data', 'Diskussion']}
        urls={[`/report/${report.id}`, `/report/${report.id}/discussion`]}
        activeIndex={0}
      />
      <ActionWrapper compact={true}>
        <SendButton
          disabled={!isReportOpen(report)}
          report={report}
          teamId={teamData.id}
        />
      </ActionWrapper>
      <GeneralStatus report={report} qualificationResults={result} />
      <DataStatus
        teamData={teamData}
        report={report}
        qualificationResults={result}
      />
      <Debug value={report} />
    </>
  )
}

export default ReporterView
