import { useTeam, useReport } from 'providers/Session'
import { useRecent } from 'providers/Recent'

import Loader from 'components/Loader'
import Page from 'components/Page'
import CreditorView from './CreditorView'
import ReporterView from './ReporterView'

const Report = ({ match }) => {
  const { id: reportId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const { data: report, loading: loadingReport } = useReport(reportId, teamData)
  const loading = loadingTeam || loadingReport

  console.log(report?.title.displayValue)
  useRecent('report', reportId, report?.title.displayValue)

  return (
    <Page title={report?.title.displayValue}>
      {loading ? (
        <Loader />
      ) : teamData.teamData.type === 'creditor' ? (
        <CreditorView
          teamData={teamData}
          report={{ id: reportId, ...report }}
        />
      ) : (
        <ReporterView
          teamData={teamData}
          report={{ id: reportId, ...report }}
        />
      )}
    </Page>
  )
}

export default Report
