import { pick } from 'lodash'
import { getValue } from 'utils/data'
import { requireProps, fulfilledAll } from '../utils'

export const title = 'Dalslands Sparbanks Hållbarhetsanalys — Intern'

export const description = `Dalslands sparbanks hållbarhetsanalys

Rådgivarens bedömning

Samtliga krav måste vara uppfyllda. Uppfyllda krav garanterar inte positivt kreditbeslut.`

export const internal = true

export const calculateTotal = fulfilledAll

export const requirements = [
  {
    label: '1',
    description: `Ej med på uteslutningslista`,
    getData: ({ report }) => pick(report, 'blackListed'),
    getFulfilled: ({ blackListed }) => {
      requireProps({ blackListed })
      return !getValue(blackListed)
    },
  },
]
