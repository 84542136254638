import { createContext, useEffect, useState, useContext } from 'react'
import { getFirebase, convertDates, Timestamp } from 'utils/firebase'
import { AuthContext } from './Auth'

export const UserContext = createContext({
  loading: true,
})

export const UserProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [state, setState] = useState({
    loading: true,
  })

  const { db, set } = getFirebase()
  const uid = user?.uid

  useEffect(() => {
    const unsubscribe = uid
      ? db
          .collection('accounts')
          .doc(uid)
          .onSnapshot(doc => {
            const account = convertDates(doc.data())
            const activeTeam = account.teams[account.activeTeam]
              ? account.activeTeam
              : Object.keys(account.teams).sort()[0]
            setState({
              loading: false,
              uid,
              ...account,
              team: activeTeam,
            })
          })
      : null

    return () => {
      unsubscribe?.()
      setState({ loading: true })
    }
  }, [db, uid])

  const setActiveTeam = team => {
    if (uid) {
      return set(`accounts/${uid}`, {
        activeTeam: team,
      })
    }
  }

  const setPreferences = newPrefs => {
    if (uid) {
      return set(`accounts/${uid}`, {
        preferences: newPrefs,
      })
    }
  }

  const setRecent = async (type, id, label) => {
    if (uid && state.team) {
      return set(`accounts/${uid}`, {
        recent: {
          [state.team]: {
            [type]: {
              [id]: { timestamp: Timestamp.now(), label },
            },
          },
        },
      })
    }
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        setActiveTeam,
        setPreferences,
        setRecent,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
