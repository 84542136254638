import { useState } from 'react'
import styled from 'styled-components'
import { useUser, addReport } from 'providers/Session'
import { theme } from 'styled-tools'
import UnstyledSelect from 'components/form/Select'
import Button from 'components/Button'
import Card from 'components/Card'

const Wrapper = styled.div`
  padding: 0.6rem 1.2rem;
`

const ButtonWrapper = styled.div`
  margin-left: 0.6rem;
  width: 33%;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`

const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  width: 33%;
`

const Select = styled(UnstyledSelect)`
  width: 33%;
`

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.6rem 0;
`

const StyledButton = styled(Button)`
  margin: 0;
`

const ErrorMsg = styled.div`
  color: ${theme('colors.error')};
  padding: 0.3rem;
`

const ReportSettingsForm = ({
  creditors,
  creditor,
  setCreditor,
  reportTitle,
  reportData,
}) => {
  const [state, setState] = useState({})
  const { team } = useUser()
  const creditorOptions = Object.entries(creditors ?? {})
    .map(([id, creditor]) => ({ value: id, label: creditor.name.displayValue }))
    .sort(({ label: l1 }, { label: l2 }) => l1.localeCompare(l2))

  const sendReport = async () => {
    try {
      setState({ sending: true })
      await addReport({
        teamId: team,
        creditor,
        title: reportTitle,
        data: reportData,
      })
      setState({ done: true })
    } catch (err) {
      console.log(err)
      setState({ error: 'Något gick fel' })
    }
  }

  return (
    <Card title="Inställningar">
      <Wrapper>
        {state.done ? (
          <p>Rapporten har skickats</p>
        ) : !creditors ? (
          <p>Inga kreditgivare</p>
        ) : (
          <>
            <RowWrapper>
              <Label>Kreditgivare</Label>
              <Select
                options={creditorOptions}
                value={creditor}
                onChange={e => setCreditor(e.target.value)}
              />
              <ButtonWrapper>
                <StyledButton disabled={state.sending} onClick={sendReport}>
                  Skicka
                </StyledButton>
              </ButtonWrapper>
            </RowWrapper>
            {state.error && <ErrorMsg>{state.error}</ErrorMsg>}
          </>
        )}
      </Wrapper>
    </Card>
  )
}

export default ReportSettingsForm
