import { SetThree12 as colorScheme } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer'
import { theme } from 'theme'
import { H2, H3, Div, Span, Table, Tr, Th, Td } from 'components/Pdf/elements'
import { InlineMarkdown as Markdown } from 'components/Markdown/Pdf'
import { Row, Column } from 'components/Pdf/elements/Layout'
import ComboChart from './ComboChart'
import certificationTypes from 'data/certificationTypes'
import Coverage from './Coverage'

const SmallColumn = ({ title, children }) => (
  <Column width={1 / 3} style={{ marginBottom: '2em' }}>
    <H3 style={{ marginBottom: '2em', lineHeight: 1.1 }}>{title}</H3>
    {children}
  </Column>
)

const Organization = ({
  name,
  details,
  comments,
  properties,
  results: { progress, energyClass, certifications },
}) => (
  <Row>
    <Column style={{ marginTop: 0, marginBottom: '5em' }} width={1}>
      <Table columns={[7, 3]}>
        <Tr />
        {details.map(({ label, value, indent = 0 }) => (
          <Tr key={label}>
            <Th style={{ paddingLeft: 1.2 + indent + 'em' }}>
              <Markdown>{label}</Markdown>
            </Th>
            <Td>
              <Markdown>{value.toString()}</Markdown>
            </Td>
          </Tr>
        ))}
      </Table>
    </Column>
    <SmallColumn title="Datatäckning">
      <Coverage data={[progress, 0, 1 - progress]} />
    </SmallColumn>
    <SmallColumn title="Fastighet per energiklass">
      <ComboChart
        labels={'ABCDEFG?'.split('')}
        datasets={[
          { label: 'Yta [m2]', data: energyClass.totalArea, type: 'line' },
          {
            label: 'Antal fastigheter',
            data: energyClass.properties,
            color: 'ABCDEFG?'
              .split('')
              .map(cls =>
                cls !== '?' ? theme.colors.energyClass[cls] : theme.colors.light
              ),
          },
        ]}
      />
    </SmallColumn>
    <SmallColumn title="Fastigheter per certifieringsnivå">
      <ComboChart
        labels={[
          'Unclassified',
          'Pass/Brons/Godkänd',
          'Good/Silver/Godkänd',
          'Very Good/Gold/Guld',
          'Excellent/Platinum',
          'Outstanding',
          'Ocertifierad',
        ]}
        datasets={[
          { label: 'Yta m[2]', data: certifications.totalArea, type: 'line' },
          ...certifications.properties.map(({ id, data }) => {
            return {
              id,
              label: certificationTypes[id]?.label ?? 'Ocertifierad',
              data: data.map(({ value }) => value),
              stack: 0,
              color:
                id === 'none'
                  ? theme.colors.light
                  : colorScheme[Object.keys(certificationTypes).indexOf(id)],
            }
          }),
        ]}
      />
    </SmallColumn>
    <Column>
      <H2 style={{ margin: '3em 0 0' }}>Rådgivarens kommentarer</H2>
      <Table columns={[3, 7]}>
        <Tr />
        {comments.map(({ label, value }) => (
          <Tr key={label}>
            <Th>
              <Span>{label}</Span>
            </Th>
            <Td>
              <Span>{value ?? '(Inga kommentarer)'}</Span>
            </Td>
          </Tr>
        ))}
      </Table>
    </Column>
    <Column>
      <H2>Fastigheter</H2>
      <Table columns={[4, 3, 2, 3, 2]}>
        <Tr>
          <Th>
            <Span>Fastighet</Span>
          </Th>
          <Th>
            <Span>Typ</Span>
          </Th>
          <Th>
            <Span>Energiklass</Span>
          </Th>
          <Th>
            <Span>Certifiering</Span>
          </Th>
          <Th>
            <Span>Täckning</Span>
          </Th>
        </Tr>
        {properties.map(
          ({
            id,
            title,
            type,
            energyClass,
            certifications,
            progress,
            focus,
          }) => (
            <Tr
              key={id}
              style={{
                backgroundColor: focus ? theme.colors.highlight : undefined,
              }}
            >
              <Th>
                <Span>{title}</Span>
              </Th>
              <Td>
                <Span>{type}</Span>
              </Td>
              <Td>
                <Div
                  style={{
                    backgroundColor:
                      theme.colors.energyClass[energyClass] ??
                      theme.colors.light,
                    width: '2em',
                    height: '2em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    borderRadius: '0.3em',
                  }}
                >
                  <Span>{energyClass ?? '?'}</Span>
                </Div>
              </Td>
              <Td>
                <Span>{certifications ?? '–'}</Span>
              </Td>
              <Td>
                <Span>{(progress * 100).toFixed()} %</Span>
              </Td>
            </Tr>
          )
        )}
      </Table>
    </Column>
  </Row>
)

export default Organization
