import { createElement } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { getValue } from 'utils/data'

import UnstyledList from 'components/List'
import { Link } from 'react-router-dom'
import Icon from 'components/Icon'
import Card from 'components/Card'

const List = styled(UnstyledList)`
  li {
    display: block;
    padding: 0;
  }
`

const Progress = styled.div`
  flex-grow: 0;
`

const RowWrapper = styled.div`
  padding: 0.6rem 1.2rem;
  display: flex;
  justify-content: stretch;
  align-items: center;

  background-color: ${ifProp(
    '$focus',
    theme('colors.highlight'),
    'transparent'
  )};
  > a,
  > div:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
    > svg {
      margin: 0;
      margin-right: 0.5rem;
    }
    p {
      ${ifProp('$header', 'font-weight: 500;', '')}
      ${ifProp('$focus', 'font-weight: 500;', '')}
      margin: 0;
    }
  }
`

const Div = styled.div``

const Row = ({ title, url, header, icon, progress, focus }) => (
  <RowWrapper $header={header} $focus={focus}>
    {createElement(url ? Link : Div, { to: url }, [
      icon && <Icon type={icon} key="icon" />,
      <p key="p">{title}</p>,
    ])}
    <Progress>{progress ?? '—'}</Progress>
  </RowWrapper>
)

const DataStatus = ({ teamData, report, qualificationResults: result }) => {
  const orgId = Object.keys(teamData.organizations)[0]

  const items = result
    ? [
        {
          id: 'total',
          icon: 'pie',
          title: 'Totalt',
          progress: result.progressDisplay,
          header: true,
        },
        {
          id: orgId,
          icon: 'organization',
          title: getValue(teamData.organizations[orgId].name),
          url: `/organization/${orgId}`,
          progress: result.organizations[orgId]?.progressDisplay,
        },
        ...Object.entries(teamData.properties)
          .map(([id, prop]) => ({
            id,
            icon: 'building',
            title: getValue(prop.propertyId),
            url: `/property/${id}`,
            progress: result.properties[id]?.progressDisplay,
            focus: id === report.data?.property,
          }))
          .sort((p1, p2) =>
            p1.focus
              ? -1
              : p2.focus
              ? 1
              : p1.title.localeCompare(p2.title, 'sv')
          ),
      ]
    : []

  return (
    <Card title="Data">
      <List data={items}>
        <Row />
      </List>
    </Card>
  )
}

export default DataStatus
