import { Document as RawDocument, StyleSheet } from '@react-pdf/renderer'
import './fonts'

const styles = StyleSheet.create({
  document: {
    fontFamily: 'Open Sans',
    letterSpacing: 0.015,
    fontWeight: 300,
    color: '#000000',
  },
})

const Document = ({ style, ...props }) => (
  <RawDocument style={styles.document} {...props} />
)

export default Document
