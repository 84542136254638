import addPropMeta from './addPropMeta'
import { mapValues } from 'lodash'
import { toOptions, getValue } from 'utils/data'
import { capitalizeSentence } from 'utils/string'
import { certificationLabel } from 'data/certificationTypes'
import { isRequired, isOrgNr, matches, isNumber } from 'utils/validation'
import typecodes from 'data/typecodes.json'
// Allowed properties:
// label: string
// description: string
// type: string
// unit: string
// sourced: boolean
// labels: array (for type boolean)
// options: array|fn (for type select)
// link: string|fn
// format: fn (for display value)
// fields: object (for type section)
// formatItem: fn (for type list)
// validate: (value: any, env: TeamData) => string (erormessage) | undefined
// transform: {read: fn, write: fn} (for form editing)

const meta = {
  propertyId: {
    label: 'Fastighetsbeteckning',
    validate: isRequired,
    description: `Fastighetsbeteckning enligt fastighetsregistret. Format: [Kommun Trakt Enhet:Block]. 

[Läs mer](https://www.lantmateriet.se/sv/Kartor-och-geografisk-information/Kartor/min-karta/hitta-fastighetsbeteckning-i-min-karta/)`,
  },
  organization: {
    label: 'Organisation',
    sourced: false,
    type: 'select',
    options: ({ team }) => [
      { value: '', label: '---' },
      ...Object.entries(team?.organizations ?? {}).map(([id, org]) => ({
        value: id,
        label: getValue(org.name),
      })),
    ],
    link: (id, { reportId }) =>
      reportId
        ? `/report/${reportId}/organization/${id}`
        : `/organization/${id}`,
  },
  owner: {
    label: 'Registrerad Ägare',
    description: `Registrerad ägare enligt taxering`,
    format: ({ orgNr, name }) =>
      name
        ? `${capitalizeSentence(name.toLowerCase())} ${
            orgNr ? `\n(${orgNr})` : ''
          }`
        : orgNr,
    type: 'section',
    fields: {
      name: {
        label: 'Namn',
        validate: isRequired,
        placeholder: 'Namn',
      },
      orgNr: {
        label: 'Organisationsnummer',
        validate: isOrgNr,
        placeholder: 'XXXXXX-XXXX',
      },
    },
  },
  ownershipShare: {
    label: 'Ägarandel',
  },
  address: {
    label: 'Adress',
    format: ({ address, zipCode, city }) =>
      `${address ? `${address}\n` : ''}${[zipCode, city]
        .filter(i => i)
        .join(' ')}`,
    type: 'section',
    fields: {
      address: {
        label: 'Adress',
        validate: isRequired,
      },
      zipCode: {
        label: 'Postnr',
        validate: matches(/^\d{5}$/, 'Måste bestå av fem siffror'),
      },
      city: {
        label: 'Ort',
        validate: isRequired,
      },
    },
  },
  yearOfConstruction: {
    label: 'Nybyggnadsår',
    type: 'year',
    description: `År då byggnaden färdigställdes.

Värdet finns i fastighetstaxering.`,
  },
  yearOfModification: {
    label: 'Tillbyggnadsår',
    type: 'year',
    description: `Året då byggnaden renoverades (om relevant).

Värdet finns i fastighetstaxering.`,
  },
  taxedValue: {
    label: 'Taxeringsvärde',
    type: 'number.0',
    unit: 'MSEK',
  },
  marketValue: {
    label: 'Marknadsvärde',
    type: 'number.0',
    unit: 'MSEK',
  },
  mortgaging: {
    label: 'Pantsättning',
    type: 'number.0',
    unit: 'MSEK',
  },
  floorArea: {
    label: 'Boyta',
    unit: 'm^{2}',
    type: 'number.0',
    description: `Byggnadens totala yta enligt taxering`,
  },
  tempArea: {
    label: 'A_{temp}',
    description: `Yta som används som underlag till energideklaration.

Värdet finns på fastighetens energideklaration.
[Läs mer](https://www.boverket.se/sv/om-boverket/publicerat-av-boverket/fragor--svar/bbr-boverkets-byggregler/avsnitt-9-energihushallning/atemp/vad-ar-atemp-for-nagot/)`,
    unit: 'm^{2}',
    type: 'number.0',
  },
  propertyType: {
    label: 'Fastighetstyp',
    type: 'select',
    description: `Fastighetens typkod enligt taxering.

Värdet finns i fastighetsdeklaration.
[Läs mer](https://skatteverket.se/foretag/skatterochavdrag/fastighet/fastighetstaxering/typkoder.4.3f4496fd14864cc5ac9126d.html)`,
    options: Object.values(typecodes).map(
      ({ code, category, subcategory }) => ({
        value: code,
        label: `${code}: ${category} ${subcategory}`,
      })
    ),
  },

  energyClass: {
    label: 'Energiklass',
    type: 'select',
    options: 'ABCDEFG'.split('').map(l => ({ value: l, label: l })),
    description: `Energiklass A - G är en klassificering baserat på fastighetens energianvändning, enligt Boverkets Byggregler (BBR)

Värdet finns på fastighetens energideklaration.`,
  },
  primaryEnergyDemand: {
    label: 'Primärenergital',
    type: 'number',
    unit: 'kWh/m^{2}',
    description: `Primärenergitalet är Boverkets mätvärde för att ange en byggnads energiprestanda
sedan 1/1 2019. 

Värdet finns på fastighetens energideklaration.`,
  },
  specificEnergyDemand: {
    label: 'Specifik Energianvändning',
    type: 'number',
    unit: 'kWh/m^{2}',
    description: `Specifik Energianvändning var Boverkets mätvärde för att ange en byggnads energiprestanda
före 1/1 2019. 

Värdet finns på fastighetens energideklaration.`,
  },
  epcExpirationDate: {
    label: 'Energideklaration giltig till',
    type: 'date',
    description: `Utgångsdatum för energideklaration, 10 år efter energideklarationens utfärdande.

Värdet finns på fastighetens energideklaration.`,
  },
  epcBuildingClassification: {
    label: 'Byggnadskategori (EPC)',
    type: 'select',
    options: toOptions({
      singleDoubleResident: 'En- och tvåbostadshus',
      apartmentBlock: 'Flerbostadshus',
      special: 'Lokal- och specialbyggnader',
      commercial: 'Lokalbyggnader',
      unknown: '(Okänd)',
    }),
  },

  mainHeatingType: {
    label: 'Huvudsaklig uppvärmning',
    description: `Mekanism för uppvärmning av fastigheten. Inkludera alla alternativ som bidrar signifikant till 
uppvärmningen. 

Värdet finns i regel angivet i fastighetens energideklaration.`,
    type: 'select',
    multiple: true,
    options: toOptions({
      directElectricity: 'El (direktverkande)',
      airborneElectricity: 'El (vattenburen)',
      waterborneElectricity: 'El (vattenburen)',
      oil: 'Fossil olja, Eldningsolja',
      districtHeating: 'Fjärrvärme',
      biofuels: 'Biobränsle',
      fossilGas: 'Fossil gas (naturgas)',
      geothermal: 'Markvärmepump',
      wood: 'Ved',
      heatPumpAirWater: 'Värmepump luft/vatten',
      heatPumpAirAir: 'Värmepump luft/luft',
      heatPumpExhaustAir: 'Värmepump frånluft',
      other: 'Annat',
    }),
  },

  yearlyEnergyConsumption: {
    label: 'Årlig energianvändning',
    description: 'Normalårsjusterad',
    type: 'number.0',
    unit: 'kWh',
  },

  energyConsumptionByType: {
    label: 'Årlig energianvändning per energislag',
    type: 'section',
    fields: {
      electricity: {
        label: 'El',
        type: 'number',
        unit: 'kWh',
      },
      remoteHeating: {
        label: 'Fjärrvärme',
        type: 'number',
        unit: 'kWh',
      },
      remoteCooling: {
        label: 'Fjärrkyla',
        type: 'number',
        unit: 'kWh',
      },
      biofuels: {
        label: 'Biobränsle',
        type: 'number',
        unit: 'kWh',
      },
      fossilOil: {
        label: 'Fossil olja',
        type: 'number',
        unit: 'kWh',
      },
      fossilGas: {
        label: 'Fossil gas',
        type: 'number',
        unit: 'kWh',
      },
    },
  },
  energyMix: {
    type: 'section',
    label: 'Elmix',
    fields: {
      renewable: {
        label: 'Andel förnybart',
        type: 'percentage',
        unit: '%',
      },
      nuclear: {
        label: 'Andel kärnkraft',
        type: 'percentage',
        unit: '%',
      },
      fossil: {
        label: 'Andel fossilt',
        type: 'percentage',
        unit: '%',
      },
      other: {
        label: 'Andel övrigt',
        type: 'percentage',
        unit: '%',
      },
    },
  },

  airPermeabilityTested: {
    label: 'Täthetsprov',
    type: 'boolean',
    labels: ['Genomfört', 'Ej genomfört'],
    description: `Har lufttäthetsprovning/provtryckning gjorts för att kontrollera luftläckage/genomtränglighet?

Om täthetsprov är genomfört bör protokollet inkluderas som dokument.`,
  },
  thermography: {
    label: 'Termografiundersökning',
    description: `Har undersökning med värmekamera genomförts för att fastställa värmegenomgångskoefficient?

Om ja, ange även värmegenomgångskoefficient.`,
    type: 'boolean',
    labels: ['Genomfört', 'Ej genomfört'],
  },
  uMean: {
    label: 'Genomsnittlig värmegenomgångskoefficient (U_{m})',
    description: `Ett mått på hur väl fastigheten är isolerad. Kan uppskattas i nya fastigheter eller med värmekamera 
(termografi).`,
    type: 'number.2',
    unit: 'W/m^{2}K',
    validate: isNumber,
  },
  thermographyAlternative: {
    label: 'Alternativ kvalitetskontroll för köldläckage',
    description: `Har någon annan metod än termografi använts för att uppskatta fastighetens köldläckage?`,
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },
  combinedHvacOutput: {
    label: 'Kombinerad energiklassning för vvk-system',
    type: 'number',
    unit: 'kW',
    description:
      'Sammanlagd energiklassning för värme- ventilations- och kylsystem i hela fastigheten',
  },

  automatedHvacControlSystem: {
    label: 'Automatiserat kontroll-/övervakningssystem för vvk',
    description: `Finns ett automatiskt system för att monitorera och optimera prestanda för vvk-system?`,
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  hvacMonitoringProcess: {
    label: 'Process för kontroll och övervakning av vvk-system',
    description: `Finns en manuell process för att monitorera och optimera prestanda för vvk-system?`,
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  energyPerformanceContract: {
    label: 'Energy Performance Contract',
    description: `Omfattas fastigheten av ett Energy Performance Contract/EPC-projekt?

EPC-projekt är större projekt för att förbättra energiprestandan i en eller flera fastigheter, där 
en entreprenör tar en helhetsansvar för prestandaförbättringen.`,
    type: 'boolean',
    labels: ['Ja', 'Nej'],
  },

  gwp: {
    label: 'GWP-kalkyl',
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  climateRiskAnalysis: {
    label: 'Klimatriskanalys',
    type: 'boolean',
    labels: ['Finns', 'Finns ej'],
  },

  certifications: {
    label: 'Certifieringar',
    type: 'list',
    format: ({ type, level, dateIssued }) =>
      certificationLabel({
        type,
        level,
        dateIssued,
      }),
  },

  documents: {
    label: 'Dokument',
    type: 'list',
    itemType: 'section',
    format: ({ name }) => name,
    fields: {
      name: {
        label: 'Namn',
      },
      description: {
        label: 'Beskrivning',
        type: 'textArea',
      },
    },
    sourced: false,
  },

  plans: {
    label: 'Planer',
    type: 'list',
    format: (_, { itemId, team }) =>
      getValue(team.plans?.[itemId]?.title) ?? '...',
    link: (_, { itemId, reportId }) =>
      reportId ? `/report/${reportId}/plan/${itemId}` : `/plan/${itemId}`,
    sourced: false,
  },
}

const transform = (propertyData, env) =>
  mapValues(meta, (attrMeta, key) =>
    addPropMeta(propertyData[key], attrMeta, env)
  )

export default transform
