import { StyleSheet } from '@react-pdf/renderer'
import { View } from './elements'
const styles = StyleSheet.create({
  row: {
    margin: `0 -14`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    padding: `0 14`,
  },
})

export const Row = ({ style, ...props }) => (
  <View style={[styles.row, style]} {...props} />
)

export const Column = ({ style, width, ...props }) => (
  <View
    wrap={false}
    style={[{ width: `${100 * (width ?? 1)}%` }, styles.column, style]}
    {...props}
  />
)
