import styled from 'styled-components'
import { useTeam } from 'providers/Session'
import Loader from 'components/Loader'
import List from 'components/List'
import Debug from 'components/Debug'
import EnergyClass from 'components/EnergyClass'
import Page from 'components/Page'
import Card from 'components/Card'
import NewPropertyButton from './NewPropertyButton'

import { HeadingWrapper, Source } from 'components/List'

const Wrapper = styled(Card)`
  padding-bottom: 1.2rem;
`

const StyledEnergyClass = styled(EnergyClass)`
  width: 3rem;
  height: 3rem;
  font-size: 1.8rem;
  line-height: 3rem;
`

const PropertyList = props => {
  const { properties, loading } = useTeam()
  const items = properties
    ? Object.entries(properties ?? {}).map(([id, property]) => ({
        id,
        title: property.propertyId ?? '',
        subtitle: property.address ?? '',
        value: property.energyClass,
        url: `/property/${id}`,
      }))
    : null

  return (
    <Page title="Fastigheter">
      <Wrapper>
        <HeadingWrapper>
          <NewPropertyButton />
        </HeadingWrapper>
        {loading ? (
          <Loader />
        ) : !items ? (
          <div></div>
        ) : (
          <List
            data={items}
            filter={filterStr =>
              ({ title, subtitle }) =>
                title.toLowerCase().includes(filterStr.toLowerCase()) ||
                subtitle.toLowerCase().includes(filterStr.toLowerCase())}
            sort={({ title: t1 }, { title: t2 }) => t1.localeCompare(t2, 'sv')}
          >
            <Source />
            <StyledEnergyClass />
          </List>
        )}
      </Wrapper>
      <Debug value={properties} />
    </Page>
  )
}

export default PropertyList
