import { useState, useEffect } from 'react'
import { BubbleChart } from 'components/Chart'
import SimpleTable from 'components/SimpleTable'
import { getBounds } from 'utils/data'

export const title = 'Primärenergital och Marknadsvärde'
export const Table = ({ properties, filter }) => {
  const meta = Object.values(properties)[0]
  const columns = ['propertyId', 'marketValue', 'primaryEnergyDemand'].map(
    id => ({
      id,
      ...meta[id],
    })
  )

  const rows = Object.entries(properties).map(([id, item]) => ({
    id,
    url: `/property/${id}`,
    label: item.propertyId.displayValue,
    cellValues: columns.slice(1).map(({ id }) => item[id]),
  }))

  // console.log(filter)

  return (
    <SimpleTable
      columns={columns}
      rows={rows}
      filter={filter}
      sort={[
        { column: 'primaryEnergyDemand', order: 'desc' },
        { column: 'marketValue', order: 'desc' },
      ]}
    />
  )
}

export const Chart = ({ properties, onClick }) => {
  const [chartParams, setChartParams] = useState({ data: {} })

  useEffect(() => {
    setChartParams({
      title,
      ...getChartParams(properties, onClick),
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties])

  return <BubbleChart {...chartParams} />
}

const getChartParams = (properties, onClick) => {
  const chartOptions = {
    scales: {
      xAxis: {
        title: {
          display: true,
          text: 'Marknadsvärde [MSEK]',
        },
      },
      yAxis: {
        title: {
          text: 'Primärenergital [kWh/m²]',
          display: true,
        },
      },
    },
  }

  const plugin = {
    beforeDraw: (chart, easing) => {
      const chartArea = chart.chartArea
      const ctx = chart.ctx
      const y100 = chart.scales.yAxis
        ? chart.scales.yAxis.getPixelForValue(100) - chartArea.top
        : 0

      const xMid = (chartArea.right - chartArea.left) / 2

      ctx.fillStyle = '#fcc'
      ctx.fillRect(chartArea.left + xMid, chartArea.top, xMid, y100)
    },
  }

  const bubbleData = Object.entries(properties)
    .filter(([id, item]) => item.primaryEnergyDemand.value)
    .map(([id, item], i) => {
      const ped = item.primaryEnergyDemand.value
      const aTemp = item.tempArea.value ?? 1000
      const marketValue =
        (item.marketValue.value ?? 50000000 + Math.random() * 50000000) /
        1000000

      return {
        x: marketValue,
        y: ped,
        r: aTemp,
        c: 1,
        title: item.propertyId.displayValue,
        subtitle: `${ped} kWh/m² @ ${marketValue.toFixed(1)} MSEK`,
        id,
      }
    })

  const bounds = getBounds(bubbleData, ['x', 'y', 'r'])
  const scale = (0.1 * Math.max(bounds.xr, bounds.yr)) / bounds.rr

  const scaledBubbleData = bubbleData.map(datum => ({
    ...datum,
    r: datum.r * scale,
  }))

  const datasets = [
    {
      label: 'Fastigheter',
      clip: 0,
      data: scaledBubbleData,
    },
  ]

  return {
    data: { datasets },
    plugins: [plugin],
    options: chartOptions,
    onClick: item => {
      onClick(item.value?.id ? { id: item.value.id } : {})
    },
  }
}
