import { createContext, useContext } from 'react'
import { mapValues } from 'lodash'
import {
  View as RawView,
  Text as RawText,
  Image as RawImage,
  Link as RawLink,
  Svg as RawSvg,
} from '@react-pdf/renderer'

const getStyle = style => {
  if (Array.isArray(style)) {
    return Object.assign({}, ...style.map(getStyle))
  } else {
    return style ?? {}
  }
}

const getSize = (size, fontSize) => {
  const m = size?.match?.(/^(-?\d+(\.\d+)?em)$/)
  return m ? parseFloat(m[1]) * fontSize : size
}

const convert = (str, fontSize) =>
  str.replace(/(-?\d+(\.\d+)?em)/g, (_, size) => parseFloat(size) * fontSize)

const FontSizeContext = createContext(9)
const wrap =
  Component =>
  ({ style, ...props }) => {
    let fontSize = useContext(FontSizeContext)
    const { fontSize: nextFontSize, ...mergedStyle } = getStyle(style)

    if (nextFontSize) {
      fontSize = getSize(nextFontSize, fontSize)
    }
    const newStyle = {
      fontSize,
      ...mapValues(mergedStyle, v =>
        typeof v === 'string' ? convert(v, fontSize) : v
      ),
    }
    if (nextFontSize) {
      return (
        <FontSizeContext.Provider value={fontSize}>
          <Component style={newStyle} {...props} />
        </FontSizeContext.Provider>
      )
    } else {
      return <Component style={newStyle} {...props} />
    }
  }

export const Text = wrap(RawText)
export const View = wrap(RawView)
export const Image = wrap(RawImage)
export const Link = wrap(RawLink)
export const Svg = wrap(RawSvg)
