import ReactMarkdown from 'react-markdown'
import {
  Div,
  P,
  H2,
  H3,
  H4,
  Strong,
  Em,
  Sub,
  Sup,
  Span,
  Ul,
  Ol,
  Li,
} from '../Pdf/elements'
import { stripNewLines, superscript, subscript } from './plugins'

export const InlineMarkdown = ({ components = {}, ...props }) => (
  <ReactMarkdown
    {...props}
    allowedElements={[
      'p',
      'h2',
      'h3',
      'h4',
      'ul',
      'li',
      'ol',
      'sub',
      'sup',
      'span',
      'em',
      'strong',
    ]}
    skipHtml
    components={{
      p: props => <P style={{ marginBottom: 0 }} {...props} />,
      h2: H2,
      h3: H3,
      h4: H4,
      ul: Ul,
      ol: Ol,
      li: Li,
      em: Em,
      strong: Strong,
      sub: Sub,
      sup: Sup,
      span: Span,
      ...components,
    }}
    rehypePlugins={[stripNewLines, subscript, superscript]}
  />
)
const Markdown = props => (
  <Div>
    <InlineMarkdown {...props} components={{ p: P }} />
  </Div>
)

export default Markdown
