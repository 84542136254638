import { useMemo } from 'react'
import {
  useTeam,
  useProperty as _useProperty,
  useReport,
  useSnapshot,
} from 'providers/Session'
import { useRecent } from 'providers/Recent'

import Loader from 'components/Loader'
import { getLatestSnapshotId, getValue } from 'utils/data'
import Page from 'components/Page'
import Debug from 'components/Debug'
import { StaticSections, DocumentsSection } from 'components/Section'
import CertificationsSection from './CertificationsSection'
import PlansSection from './PlansSection'

import { qualifyObject } from 'qualifier'

const viewModel = {
  propertyData: {
    label: 'Fastighetsdata',
    keys: [
      'propertyId',
      'organization',
      'owner',
      // 'ownershipShare',
      'address',
      'yearOfConstruction',
      'yearOfModification',
      // 'taxedValue',
      // 'marketValue',
      // 'mortgaging',
      'floorArea',
      'tempArea',
      'propertyType',
    ],
  },
  epc: {
    label: 'Energideklaration',
    keys: [
      'energyClass',
      'primaryEnergyDemand',
      'specificEnergyDemand',
      'mainHeatingType',
      'epcExpirationDate',
    ],
  },
  // energyConsumption: {
  //   label: 'Energianvändning',
  //   keys: ['yearlyEnergyConsumption', 'energyConsumptionByType', 'energyMix'],
  // },
  hvac: {
    label: 'Värme, ventilation och Kylning',
    keys: [
      'airPermeabilityTested',
      'thermography',
      'uMean',
      'thermographyAlternative',
      'combinedHvacOutput',
      'automatedHvacControlSystem',
      'hvacMonitoringProcess',
      'energyPerformanceContract',
    ],
  },
  // misc: {
  //   label: 'Analyser',
  //   keys: ['gwp', 'climateRiskAnalysis'],
  // },
}

// Get property from db or from snapshot
const useProperty = ({ propertyId, reportId }, teamData) => {
  const {
    loading: loadingProperty,
    data: propertyData,
    ...propertyProps
  } = _useProperty(propertyId, teamData)

  const { loading: loadingReport, data: reportData } = useReport(
    reportId,
    teamData
  )

  const snapshotId = getLatestSnapshotId(reportData)
  const reporterId = reportData?.reporter.value

  const { loading: loadingSnapshot, data: snapshotData } = useSnapshot(
    reporterId,
    snapshotId,
    reportId
  )

  const loading =
    loadingProperty ||
    (loadingReport && reportId) ||
    (loadingSnapshot && reportId)

  const data = propertyData ?? snapshotData?.properties[propertyId]
  return {
    loading,
    data,
    reporter: reportData ? getValue(reportData.reporter) : undefined,
    ...(propertyData ? propertyProps : {}),
  }
}

const PropertyDetails = ({ match }) => {
  const { propertyId, reportId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()

  const {
    data: propertyData,
    reporter,
    loading: loadingProperty,
    saveProperty,
    saveListItem,
    addPlan,
  } = useProperty({ propertyId, reportId }, teamData)
  const loading = loadingTeam || loadingProperty

  const save = async ({ id, value, document, comment }) =>
    saveProperty(id, { value, document, comment })

  const saveItem = async ({ id, itemId, value, document, comment }) =>
    saveListItem(id, itemId, {
      value,
      document,
      comment,
    })

  useRecent('property', propertyId, propertyData?.propertyId.displayValue)

  const { missing, inadequate } = useMemo(() => {
    return (
      qualifyObject(propertyId, 'property', propertyData, teamData) ?? {
        missing: {},
        inadequate: {},
      }
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(propertyData), teamData])

  return (
    <Page title={propertyData?.propertyId?.displayValue}>
      {loading ? (
        <Loader />
      ) : propertyData ? (
        <>
          <StaticSections
            viewModel={viewModel}
            data={propertyData}
            editable={!reportId}
            save={save}
            filePath={reporter}
            qualificationResults={{ missing, inadequate }}
          />
          <CertificationsSection
            key="certifications"
            title="Certifieringar"
            certifications={propertyData.certifications}
            editable={!reportId}
            missing={missing.certifications}
            inadequate={inadequate.certifications}
            documents={propertyData.documents?.items}
            filePath={reporter}
            save={saveItem}
          />
          <DocumentsSection
            key="documents"
            title="Dokument"
            documents={propertyData.documents}
            editable={!reportId}
            parent={propertyData}
            filePath={reporter}
            save={saveItem}
          />
          <PlansSection
            key="plans"
            title="Planer"
            missing={missing.plans}
            inadequate={inadequate.plans}
            plans={propertyData.plans}
            editable={!reportId}
            add={addPlan}
          />
        </>
      ) : (
        <div></div>
      )}
      <Debug value={propertyData} />
    </Page>
  )
}

export default PropertyDetails
