import styled from 'styled-components'
import { theme } from 'styled-tools'
import Icon from 'components/Icon'

const iconTypes = {
  missing: 'question',
  fulfilled: 'check',
  lacking: 'x',
}

const colors = {
  missing: 'medium',
  fulfilled: 'success',
  lacking: 'warning',
}

const StyledIcon = styled(Icon)`
  color: ${({ color }) => theme(`colors.${color}`)};
  width: 1rem;
  height: 1rem;
`

const CheckMark = ({ value }) => (
  <StyledIcon type={iconTypes[value]} color={colors[value]} />
)

export default CheckMark
