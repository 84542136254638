import styled from 'styled-components'
import { theme } from 'styled-tools'
import { pick } from 'lodash'
import Icon from 'components/Icon'
import UnstyledSimpleTable from 'components/SimpleTable'
import Markdown, { InlineMarkdown } from 'components/Markdown'

import Value from 'components/Value'

import { BaseSection } from 'components/Section'

import CheckMark from './CheckMark'

const SimpleTable = styled(UnstyledSimpleTable)`
  th,
  td {
    width: auto;
    &:nth-child(1) {
      width: 6rem;
      white-space: pre-wrap;
    }
    &:nth-child(2) {
      width: calc(60%);
    }
    &:nth-child(3) {
      width: calc(40%);
    }
    &:nth-child(4) {
      width: 6rem;
      text-align: center !important;
    }
    &:first-child {
      padding-left: 1.2rem;
    }
    &:last-child {
      padding-right: 1.2rem;
    }
  }

  th,
  td {
    text-align: left !important;
    vertical-align: top;
    p:first-child {
      margin-top: 0;
    }
    svg {
      display: inline;
      margin: 0;
      width: 1rem;
    }
  }
  margin-bottom: 0;
`

const Description = styled(Markdown)`
  margin-bottom: 1rem;
  p:first-child {
    margin-top: 0;
  }
  padding-right: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
`

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  padding: 1.2rem;
  margin-bottom: 1rem;
`

const Grade = styled.div`
  color: ${({ color }) => theme(`colors.${color}`)};
  display: flex;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 3px;
  padding: 0.5rem;
  svg {
    margin: 0;
    margin-right: 0.5rem;
  }
  div {
    font-weight: 500;
  }
`

const Label = styled(InlineMarkdown)`
  margin-right: 0.3rem;
  p {
    font-weight: 500;
  }
`

const InlineValue = styled(Value)`
  display: inline;
`

const Error = styled.p`
  font-weight: 500;
  color: ${theme('colors.error')};
`

const InlineError = styled(InlineMarkdown)`
  * {
    color: ${theme('colors.medium')};
  }
`

const DataRow = ({ label, value, displayValue, type, source, error }) => (
  <div>
    {label && <Label>{label}: </Label>}
    {error ? (
      <InlineError>{error}</InlineError>
    ) : (
      <>
        <InlineValue
          displayValue={displayValue}
          type={type}
          interactive={false}
        />
        {source !== undefined && <sup>{source.idx + 1}</sup>}
      </>
    )}
  </div>
)

const DataCellWrapper = styled.div`
  h2 {
    text-transform: none;
    font-weight: 500;
  }
  ul {
    list-style: '- ';
    padding-left: 0.5rem;
  }
  sup {
    font-size: 60%;
    top: -0.7em;
    margin: 0 0.1em;
  }
`

const DataCellValue = ({ value, missing }) => {
  return (
    <DataCellWrapper>
      {missing && <Error>Data saknas:</Error>}
      {Object.entries(value).map(([id, entry]) =>
        entry.type === 'list' ? (
          <div key={id}>
            <Label>{entry.label}</Label>
            <ul>
              {Object.entries(entry.items ?? {}).map(([id, item]) => (
                <li key={id}>
                  <DataRow
                    {...pick(item, 'displayValue', 'type', 'unit', 'source')}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <DataRow key={id} {...entry} />
        )
      )}
    </DataCellWrapper>
  )
}

const SourceList = styled.ol`
  width: 100%;
  padding: 0.6rem 1.2rem;
  margin: 0;
  list-style: number inside;
  font-size: 0.8rem;
`

const TableReport = ({
  title,
  description,
  fulfilled,
  result,
  sourceMap,
  property,
  plans,
}) => {
  const columns = [
    { id: 'id', label: 'Id' },
    { id: 'description', label: 'Krav' },
    { id: 'data', label: 'Data' },
    { id: 'fulfilled', label: 'OK' },
  ]

  const rows = result.map(
    ({ label, description, data, fulfilled, missing }) => ({
      label,
      cellValues: [
        { displayValue: description, type: 'markdown' },
        { value: data, missing, component: DataCellValue },
        {
          value: missing ? 'missing' : fulfilled ? 'fulfilled' : 'lacking',
          component: CheckMark,
        },
      ],
    })
  )

  return (
    <BaseSection title={title}>
      <SummaryWrapper>
        <Description>{description}</Description>
        <Grade color={fulfilled ? 'success' : 'warning'}>
          <Icon type={fulfilled ? 'check' : 'x'} />
          <div>{fulfilled ? 'Uppfyllt' : 'Ej uppfyllt'}</div>
        </Grade>
      </SummaryWrapper>
      <SimpleTable columns={columns} rows={rows} />
      <SourceList>
        {Object.values(sourceMap)
          .sort((s1, s2) => s1.idx - s2.idx)
          .map(({ label, description, timestamp, type, idx }) => (
            <li key={idx}>
              {label}
              {description ? `: ${description}` : ''}
            </li>
          ))}
      </SourceList>
    </BaseSection>
  )
}

export default TableReport
