import React from 'react'
import { createContext, useContext } from 'react'
import { StyleSheet } from '@react-pdf/renderer'
import { theme } from 'theme'
import { Row, Column } from './Layout'
import { View } from './elements'
const ColumnContext = createContext([])

const styles = StyleSheet.create({
  table: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  tr: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    margin: 0,
  },
  th: {
    fontWeight: 500,
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    paddingLeft: '1.2em',
    paddingRight: '1.2em',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  td: {
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    paddingLeft: '1.2em',
    paddingRight: '1.2em',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
})

export const Table = ({ style, columns, children, ...props }) => {
  const total = columns?.length ? columns.reduce((a, b) => a + b) : 1
  const widths = (columns ?? []).map(c => (c * 0.9) / total)
  return (
    <ColumnContext.Provider value={widths}>
      <View style={[styles.table, style]} {...props}>
        {React.Children.map(children, (child, i) => (
          <View
            style={{
              borderBottom:
                i === 0 || i === React.Children.count(children) - 1
                  ? `2px solid ${theme.colors.primary}`
                  : '0.1px solid black',
            }}
          >
            {child}
          </View>
        ))}
      </View>
    </ColumnContext.Provider>
  )
}

export const Tr = ({ style, children, ...props }) => {
  const columns = useContext(ColumnContext)
  return (
    <Row style={[styles.tr, style]} {...props}>
      {React.Children.map(children, (child, i) => (
        <Column
          width={columns[i]}
          style={child.type === Th ? styles.th : styles.td}
        >
          {child}
        </Column>
      ))}
    </Row>
  )
}

export const Th = ({ style, index, ...props }) => (
  <View style={[style]} {...props} />
)

export const Td = ({ index, style, ...props }) => (
  <View style={[style]} {...props} />
)
