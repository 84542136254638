import { useEffect } from 'react'
import { useUser } from 'providers/Session'

export const useRecent = (type, id, label) => {
  const { uid, team, setRecent } = useUser()
  useEffect(() => {
    if (uid && team && type && id && label) {
      setRecent(type, id, label)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, team, type, id, label])
}
