import {
  useTeam,
  usePlan as _usePlan,
  useReport,
  useSnapshot,
} from 'providers/Session'
import { useRecent } from 'providers/Recent'
import Loader from 'components/Loader'
import { getLatestSnapshotId, getValue } from 'utils/data'
import Page from 'components/Page'
import Debug from 'components/Debug'
import { StaticSections, DocumentsSection } from 'components/Section'

const viewModel = {
  overview: {
    label: 'Plan',
    keys: [
      'title',
      'summary',
      'property',
      'timespan',
      'planDocument',
      'totalInvestment',
    ],
  },
  targets: {
    label: 'Mål',
    keys: ['primaryEnergyDemandTarget', 'certificationTarget'],
  },
  misc: {
    label: 'Analyser',
    keys: ['climateRiskAnalysis'],
  },
  // water: {
  //   label: 'Vatten',
  //   keys: [
  //     'maxFlowTaps',
  //     'maxFlowShowers',
  //     'maxFlushWC',
  //     'avgFlushWC',
  //     'maxUseUrinals',
  //     'maxFlushUrinals',
  //   ],
  // },
  // materials: {
  //   label: 'Material och Avfall',
  //   keys: [
  //     'shareRenewableMaterials',
  //     'actionsMinimizeWaste',
  //     'actionsCircularity',
  //     'materialControls',
  //     'materialControlsResidents',
  //     'actionsPollution',
  //   ],
  // },
}

// Get property from db or from snapshot
const usePlan = ({ planId, reportId }, teamData) => {
  const {
    loading: loadingPlan,
    data: planData,
    ...planProps
  } = _usePlan(planId, teamData)

  const { loading: loadingReport, data: reportData } = useReport(
    reportId,
    teamData
  )

  const snapshotId = getLatestSnapshotId(reportData)
  const reporterId = reportData?.reporter.value

  const { loading: loadingSnapshot, data: snapshotData } = useSnapshot(
    reporterId,
    snapshotId,
    reportId
  )

  const loading =
    loadingPlan || (loadingReport && reportId) || (loadingSnapshot && reportId)

  return {
    loading,
    data: reportId ? snapshotData?.plans[planId] : planData,
    reporter: reportData ? getValue(reportData.reporter) : undefined,
    ...(planData ? planProps : {}),
  }
}

const PlanDetails = ({ match }) => {
  const { planId, reportId } = match.params
  const { loading: loadingTeam, ...teamData } = useTeam()
  const {
    data: planData,
    loading: loadingPlan,
    reporter,
    saveProperty,
    saveListItem,
  } = usePlan({ planId, reportId }, teamData)

  const loading = loadingTeam || loadingPlan

  const save = ({ id, value, document, comment }) =>
    saveProperty(id, { value, document, comment })

  const saveItem = async ({ id, itemId, value, document, comment }) =>
    saveListItem(id, itemId, { value, document, comment })

  useRecent('plan', planId, planData?.title?.displayValue)
  return (
    <Page title={planData?.title?.displayValue}>
      {loading ? (
        <Loader />
      ) : planData ? (
        <>
          <StaticSections
            viewModel={viewModel}
            data={planData}
            editable={!reportId}
            save={save}
            filePath={reporter}
          />
          <DocumentsSection
            key="documents"
            title="Dokument"
            documents={planData.documents}
            parent={planData}
            save={saveItem}
            editable={!reportId}
            filePath={reporter}
          />
        </>
      ) : (
        <div></div>
      )}
      <Debug value={planData} />
    </Page>
  )
}

export default PlanDetails
