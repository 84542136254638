import { Svg, G, Path } from '@react-pdf/renderer'

const Logo = props => (
  <Svg
    {...props}
    viewBox="0 0 204.32401 82.956945"
    xmlns="http://www.w3.org/2000/svg"
  >
    <G transform="translate(-343.763,-24.504056)">
      <Path
        d="m 413.43,40.504 11.169,8 22.339,-20 11.169,8"
        stroke="#00a800"
        strokeWidth="4"
      />
      <Path
        d="m 358.555,89.705 q -3.744,0 -7.137,-1.326 -3.393,-1.326 -5.655,-3.9 l 3.666,-4.914 q 2.184,2.106 4.329,3.081 2.145,0.975 4.095,0.975 1.482,0 2.691,-0.351 1.209,-0.351 1.95,-1.131 0.741,-0.78 0.741,-2.106 0,-1.56 -1.092,-2.457 -1.092,-0.897 -2.808,-1.482 -1.716,-0.585 -3.666,-1.131 -4.212,-1.404 -6.318,-3.939 -2.106,-2.535 -2.106,-5.889 0,-2.574 1.326,-4.953 1.326,-2.379 4.056,-3.939 2.73,-1.56 6.864,-1.56 3.744,0 6.513,0.858 2.769,0.858 5.187,2.73 l -3.354,5.304 q -1.404,-1.404 -3.315,-2.145 -1.911,-0.741 -3.471,-0.819 -1.404,-0.078 -2.496,0.351 -1.092,0.429 -1.794,1.209 -0.702,0.78 -0.702,1.794 0,1.56 1.131,2.496 1.131,0.936 2.925,1.482 1.794,0.546 3.588,1.17 2.418,0.702 4.251,1.95 1.833,1.248 2.925,3.003 1.092,1.755 1.092,4.329 0,2.964 -1.482,5.577 -1.482,2.613 -4.446,4.173 -2.964,1.56 -7.488,1.56 z"
        fill="#000000"
      />
      <Path
        d="m 409.943,85.961 q -2.184,1.794 -5.343,2.847 -3.159,1.053 -6.357,1.053 -5.46,0 -9.594,-2.145 -4.134,-2.145 -6.435,-6.006 -2.301,-3.861 -2.301,-9.243 0,-5.382 2.457,-9.282 2.457,-3.9 6.474,-5.967 4.017,-2.067 8.463,-2.067 4.212,0 7.41,1.131 3.198,1.131 5.46,3.081 l -4.29,5.694 q -1.248,-1.014 -3.237,-1.911 -1.989,-0.897 -4.563,-0.897 -2.574,0 -4.758,1.326 -2.184,1.326 -3.432,3.627 -1.248,2.301 -1.248,5.265 0,2.808 1.326,5.148 1.326,2.34 3.627,3.705 2.301,1.365 5.343,1.365 2.028,0 3.666,-0.585 1.638,-0.585 2.964,-1.599 z"
        fill="#000000"
      />
      <Path d="m 419.913,56.009 h 8.736 v 32.994 h -8.736 z" fill="#000000" />
      <Path d="m 441.661,56.009 h 8.736 v 32.994 h -8.736 z" fill="#000000" />
      <Path
        d="m 479.789,105.461 q -3.978,0 -6.864,-0.936 -2.886,-0.936 -5.109,-2.457 -2.223,-1.521 -4.173,-3.159 l 5.148,-6.006 q 2.262,2.106 4.797,3.51 2.535,1.404 6.045,1.404 3.276,0 5.655,-1.053 2.379,-1.053 3.744,-3.042 1.365,-1.989 1.365,-4.719 v -8.19 l 0.546,1.638 q -1.326,3.042 -4.836,5.226 -3.51,2.184 -8.736,2.184 -4.524,0 -8.346,-2.301 -3.822,-2.301 -6.084,-6.279 -2.262,-3.978 -2.262,-8.97 0,-5.148 2.418,-9.165 2.418,-4.017 6.318,-6.318 3.9,-2.301 8.346,-2.301 4.368,0 7.761,1.56 3.393,1.56 5.109,3.9 l -0.468,1.248 0.936,-5.226 h 8.034 v 33.15 q 0,4.602 -2.457,8.307 -2.457,3.705 -6.786,5.85 -4.329,2.145 -10.101,2.145 z M 469.493,72.077 q 0,2.964 1.365,5.382 1.365,2.418 3.744,3.822 2.379,1.404 5.343,1.404 2.574,0 4.68,-0.858 2.106,-0.858 3.588,-2.418 1.482,-1.56 2.184,-3.666 v -7.176 q -0.702,-2.106 -2.223,-3.627 -1.521,-1.521 -3.627,-2.379 -2.106,-0.858 -4.602,-0.858 -2.964,0 -5.343,1.326 -2.379,1.326 -3.744,3.666 -1.365,2.34 -1.365,5.382 z"
        fill="#000000"
      />
      <Path
        d="m 516.881,57.778 4.471,6.705 c -1.485,1.656 -0.884,0.823 -1.875,2.446 q -1.248,2.34 -1.248,5.304 0,2.886 1.248,5.265 1.248,2.379 3.432,3.783 2.184,1.404 4.836,1.404 2.886,0 5.031,-1.404 2.145,-1.404 3.354,-3.783 c 0.838,-1.936 0.43,-0.789 1.052,-3.498 h 8.866 c 0.024,2.581 -1.057,4.907 -2.118,7.164 q -2.223,4.017 -6.357,6.357 -4.134,2.34 -9.672,2.34 -5.382,0 -9.555,-2.145 -4.173,-2.145 -6.552,-6.123 -2.379,-3.978 -2.379,-9.438 0,-4.992 2.379,-9.009 c 1.293,-2.118 2.966,-4.029 5.087,-5.368 z"
        fill="#000000"
      />
      <Path
        d="m 529.341,54.563 c 2.929,0.114 5.682,0.93 8.271,2.265 q 4.095,2.301 6.318,6.318 c 1.355,2.398 2.001,5.118 2.157,7.854 h -8.812 c 0.01,-1.489 -0.578,-2.797 -1.145,-4.11 q -1.209,-2.379 -3.354,-3.783 -2.145,-1.404 -5.031,-1.404 c -1.381,0.03 -2.782,0.252 -4.008,0.95 l -4.23,-6.343 c 2.594,-1.426 5.592,-1.625 8.472,-1.783 z"
        fill="#00a800"
      />
    </G>
  </Svg>
)

export default Logo
