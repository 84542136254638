import styled from 'styled-components'
import { sortByDate } from 'utils/data'
import { useTeam, useReports } from 'providers/Session'
import { useRecent } from 'providers/Recent'
import Loader from 'components/Loader'
import Page from 'components/Page'
import Debug from 'components/Debug'
import { BaseSection, StaticSections } from 'components/Section'
import List, { Source } from 'components/List'
import { NakedButton } from 'components/Button'

const CreditorWrapper = styled.div`
  h1 {
    margin-bottom: 2rem;
  }
`

const StatusWrapper = styled.div`
  div {
    text-align: right;
    &:first-child {
      font-weight: 500;
      margin-bottom: 1rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }
`

const Status = ({ className, id, timestamp, status, deleteItem }) => (
  <StatusWrapper className={className}>
    {status && <div>Status: {status.displayValue}</div>}
    {deleteItem && (
      <div>
        <NakedButton icon="trash" kind="light" onClick={e => deleteItem(id)} />
      </div>
    )}
  </StatusWrapper>
)

const StyledList = styled(List)`
  h2 {
    font-size: 1.3rem;
  }
  margin-bottom: 0.5rem;
`

const viewModel = {
  general: {
    label: 'Information om Kreditgivaren',
    keys: ['name', 'orgNr'],
  },
}

const CreditorDetails = ({ match }) => {
  const { id: creditorId } = match.params

  const { saveProperty, loadingTeam, ...teamData } = useTeam()
  const { data: reports, loading: loadingReports } = useReports(teamData) // TODO: filter
  const loading = loadingTeam || loadingReports

  const creditorData = teamData.creditors
    ? { id: creditorId, ...teamData.creditors[creditorId] }
    : null

  useRecent('creditor', creditorId, creditorData?.name.displayValue)

  const reportItems =
    Object.keys(reports ?? {}).length > 0
      ? Object.entries(reports)
          .map(([id, item]) => ({
            id,
            title: item.title.displayValue,
            subtitle: `${item.timestamp.displayValue}`,
            status: item.status,
            url: `/report/${id}`,
          }))
          .filter(({ status }) => status !== 'deleted')
          .sort(sortByDate('timestamp', true))
      : [{ id: 'none', title: '(Inga ärenden)' }]

  const save = ({ id, value, document, comment }) =>
    saveProperty('creditor', creditorId, id, { value, document, comment })

  return (
    <Page title={creditorData?.name.displayValue}>
      {loading ? (
        <Loader />
      ) : !creditorData ? (
        <div></div>
      ) : (
        <CreditorWrapper>
          <StaticSections
            viewModel={viewModel}
            data={creditorData}
            save={save}
          />

          <BaseSection title="Ärenden">
            <StyledList data={reportItems}>
              <Source />
              <Status />
            </StyledList>
          </BaseSection>
        </CreditorWrapper>
      )}
      <Debug value={reports} />
    </Page>
  )
}

export default CreditorDetails
