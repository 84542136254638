import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useTeam } from 'providers/Session'
import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import Popup from 'components/Popup'
import Button from 'components/Button'
import useBatchFunction from 'providers/BatchFunction'
import Status from 'components/BatchFunctionStatus'

const NewPropertyPopup = styled(Popup)`
  border: 1px solid ${theme('colors.primary')};
  border-radius: 5px;
  padding: 0.6rem 1rem;
  background: ${theme('colors.white')};
  button {
    text-transform: uppercase;
  }
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`

const StyledForm = styled(Form)`
  > h2 {
    text-transform: none;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  > ${FormField} {
    margin: 0.3rem 0;
    label {
      padding-left: 0;
      span {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
  > ${Status} {
    margin: 0.3rem 0;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  margin: 1rem 0;
`

const ErrorMsg = styled.div`
  color: ${theme('colors.error')};
  padding: 0.3rem;
`

const NewPropertyButton = () => (
  <NewPropertyPopup icon="plus" label="Ny Fastighet" dialogWidth="20rem">
    <NewPropertyForm />
  </NewPropertyPopup>
)

const NewPropertyForm = ({ close }) => {
  const { id: teamId } = useTeam()
  const { run, ...fnData } = useBatchFunction()

  const addNewProperty = async propertyId => {
    await run('addPropertyToTeam', {
      teamId,
      propertyId,
    })
  }

  return (
    <Formik
      initialValues={{
        propertyId: '',
      }}
      onSubmit={async ({ propertyId }, { setSubmitting, setErrors }) => {
        try {
          await addNewProperty(propertyId)
        } catch (e) {
          console.log(e)
          setErrors({ err: e.toString() })
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, values, errors, setValues }) => (
        <StyledForm>
          <h2>Ny fastighet</h2>
          <FormField
            name="propertyId"
            label="Fastighetsbeteckning"
            description="Kommun Trakt Block:Enhet"
            placeholder="Kommun Trakt Block:Enhet"
            autoFocus={true}
          />
          {Object.entries(errors).map(([id, error]) => (
            <ErrorMsg key={id}>{error}</ErrorMsg>
          ))}
          <StyledButton
            disabled={isSubmitting || fnData.done}
            loading={isSubmitting}
            type="submit"
          >
            Lägg till
          </StyledButton>
          {fnData && (
            <Status data={fnData} successMessage="Ny fastighet tillagd" />
          )}
        </StyledForm>
      )}
    </Formik>
  )
}

export default NewPropertyButton
